import axios from "axios";
import {
  CREATE_QUIZ_RESULTS_FAIL,
  CREATE_QUIZ_RESULTS_REQUEST,
  CREATE_QUIZ_RESULTS_SUCCESS,

  GET_QUESTION_LIST_FAIL,
  GET_QUESTION_LIST_REQUEST,
  GET_QUESTION_LIST_SUCCESS,

  GET_QUIZ_FILTER_RESULTS_FAIL,

  GET_QUIZ_FILTER_RESULTS_REQUEST,

  GET_QUIZ_FILTER_RESULTS_SUCCESS,

  GET_QUIZ_RESULTS_FAIL,
  GET_QUIZ_RESULTS_REQUEST,
  GET_QUIZ_RESULTS_SUCCESS
} from "../constants/quizResults";
import { BASE_URL_LOGIN } from "./ip";
import { toast } from 'react-toastify';

export const getQuizResults = (formData) => async (dispatch, getState) => {
  try {
    dispatch({ type: GET_QUIZ_RESULTS_REQUEST });

    const { userLogin: { userInfo } } = getState();
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        'custom-clinicid-header': 1,
        'Content-Type': 'application/json'
      }
    };
    const { data } = await axios.post(
      `${BASE_URL_LOGIN}/QuizResult/GetQuizList`,
      formData,
      config
    );

    dispatch({
      type: GET_QUIZ_RESULTS_SUCCESS,
      payload: data,
    });
  } catch (err) {
    dispatch({
      type: GET_QUIZ_RESULTS_FAIL,
      payload:
        err.response && err.response.data.message
          ? err.response.data.message
          : err.message,
    });
  }
};

export const createQuiz = (formData, navigate) => async (dispatch, getState) => {
  try {
    dispatch({ type: CREATE_QUIZ_RESULTS_REQUEST });
    const { userLogin: { userInfo } } = getState();
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        'custom-clinicid-header': 1,
        'Content-Type': 'application/json'
      }
    };
    const { data } = await axios.post(
      `${BASE_URL_LOGIN}/QuizResult/AddQuizResult`,
      formData,
      config
    );

    dispatch({
      type: CREATE_QUIZ_RESULTS_SUCCESS,
      payload: data,
    });

    if (data?.message) {
      toast.success("Quiz has been created.");
      navigate()
    }
    console.log(data)
  } catch (err) {
    dispatch({
      type: CREATE_QUIZ_RESULTS_FAIL,
      payload:
        err.response && err.response.data.message
          ? err.response.data.message
          : err.message,
    });
  }
};

export const editQuiz = (formData, navigate) => async (dispatch, getState) => {
  try {
    dispatch({ type: CREATE_QUIZ_RESULTS_REQUEST });
    const { userLogin: { userInfo } } = getState();
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        'custom-clinicid-header': 1,
        'Content-Type': 'application/json'
      }
    };
    const { data } = await axios.post(
      `${BASE_URL_LOGIN}/QuizResult/AddQuizResult`,
      formData,
      config
    );

    dispatch({
      type: CREATE_QUIZ_RESULTS_SUCCESS,
      payload: data,
    });

    if (data?.message) {
      toast.success("Quiz has been updated.");
      navigate()
    }
    console.log(data)
  } catch (err) {
    dispatch({
      type: CREATE_QUIZ_RESULTS_FAIL,
      payload:
        err.response && err.response.data.message
          ? err.response.data.message
          : err.message,
    });
  }
};



export const getProductCategory = () => async (dispatch, getState) => {
  try {

    const { userLogin: { userInfo } } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        'custom-clinicid-header': userInfo.clinicid,
      }
    };

    const { data } = await axios.get(`${BASE_URL_LOGIN}/QuizResult/GetProductCategory`, config);
    return data;
  } catch (error) {
    return error.response && error.response ? error.response : error;
  }
};

export const getQuestionCriteriaByType = (login, type) => async (dispatch, getState) => {
  try {
    const { userLogin: { userInfo } } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        'custom-clinicid-header': userInfo.clinicid,
      }
    };

    const { data } = await axios.get(`${BASE_URL_LOGIN}/QuizResult/GetQuestionCriteriaByType?type=${type}`, config);
    return data;
  } catch (error) {
    return error.response && error.response ? error.response : error;
  }
};

export const getQuizResultsList = (formData) => async (dispatch, getState) => {
  try {
    dispatch({ type: GET_QUIZ_FILTER_RESULTS_REQUEST });
    const { userLogin: { userInfo } } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        'custom-clinicid-header': userInfo.clinicid,
        'Content-Type': 'application/json'
      }
    };

    const { data } = await axios.post(`${BASE_URL_LOGIN}/QuizResult/GetPatientResultList`,  formData ,config);

    dispatch({
      type: GET_QUIZ_FILTER_RESULTS_SUCCESS,
      payload: data,
    });
  } catch (err) {
    dispatch({
      type: GET_QUIZ_FILTER_RESULTS_FAIL,
      payload:
        err.response && err.response.data.message
          ? err.response.data.message
          : err.message,
    });
  }
};


export const getQuizById = (login, id) => async (dispatch, getState) => {
  try {
    const { userLogin: { userInfo } } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        'custom-clinicid-header': userInfo.clinicid,
      }
    };

    const { data } = await axios.get(`${BASE_URL_LOGIN}/QuizResult/GetQuizResultById?QuizResultId=${id}`, config);
    return data;
  } catch (error) {
    return error.response && error.response ? error.response : error;
  }
};

export const getCriteriaType = () => async (dispatch, getState) => {
  try {

    const { userLogin: { userInfo } } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        'custom-clinicid-header': userInfo.clinicid,
      }
    };

    const { data } = await axios.get(`${BASE_URL_LOGIN}/QuizResult/GetQuestionCriteriaType`, config);
    return data;
  } catch (error) {
    return error.response && error.response ? error.response : error;
  }
};


export const getQuestionList = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: GET_QUESTION_LIST_REQUEST });

    const { userLogin: { userInfo } } = getState();
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        'custom-clinicid-header': 1,
        'Content-Type': 'application/json'
      }
    };
    const { data } = await axios.get(
      `${BASE_URL_LOGIN}/QuizResult/GetQuizQuestionsList?ProductId=${id}`,
      config
    );

    dispatch({
      type: GET_QUESTION_LIST_SUCCESS,
      payload: data,
    });
  } catch (err) {
    dispatch({
      type: GET_QUESTION_LIST_FAIL,
      payload:
        err.response && err.response.data.message
          ? err.response.data.message
          : err.message,
    });
  }
};

export const enableDisableQuiz = (formData) => async (dispatch, getState) => {
  try {
    dispatch({ type: GET_QUESTION_LIST_REQUEST });

    const { userLogin: { userInfo } } = getState();
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        'custom-clinicid-header': 1,
        'Content-Type': 'application/json'
      }
    };
    const { data } = await axios.patch(
      `${BASE_URL_LOGIN}/QuizResult/EnableDisableQuiz`,
      formData,
      config
    );

    dispatch({
      type: GET_QUESTION_LIST_SUCCESS,
      payload: data,
    });
    toast.success(data.message);
  } catch (err) {
    toast.error(err.response.data.message);
    dispatch({
      type: GET_QUESTION_LIST_FAIL,
      payload:
        err.response && err.response.data.message
          ? err.response.data.message
          : err.message,
    });
  }
};

export const deleteQuiz = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: GET_QUESTION_LIST_REQUEST });

    const { userLogin: { userInfo } } = getState();
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        'custom-clinicid-header': 1,
        'Content-Type': 'application/json'
      }
    };
    const { data } = await axios.get(
      `${BASE_URL_LOGIN}/QuizResult/RemoveQuiz?Id=${id}`,
      config
    );

    dispatch({
      type: GET_QUESTION_LIST_SUCCESS,
      payload: data,
    });
    toast.success(data.message);
  } catch (err) {
    toast.error(err.response.data.message);
    dispatch({
      type: GET_QUESTION_LIST_FAIL,
      payload:
        err.response && err.response.data.message
          ? err.response.data.message
          : err.message,
    });
  }
};
