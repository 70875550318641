import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import TableRows from '../../components/Skeletons/TableRows';

export default function ShowData({ data, loading }) {
	const userLogin = useSelector((state) => state.userLogin);

	const { showFilter, quizResultsFilterList, userIndex } = data;

	return (
		<div className='tableContainer' style={{ height: showFilter ? '63vh' : '23vh' }}>
			<table className='table'>
				<thead>
					<tr>
						<th>#</th>
						<th>Provider</th>
						<th>Clinic Name</th>
						<th>Email</th>
						<th>User F/L Name</th>
						<th>Birth Date</th>
						<th>Postive Responders</th>
						<th style={{ textAlign: 'center' }}>View Results</th>

					</tr>
				</thead>
				<tbody>
					{quizResultsFilterList && !loading ? quizResultsFilterList?.data?.data?.length > 0 ? (
						quizResultsFilterList?.data?.data?.map((user, index) => (
							<tr key={index + 1}>
								<td>{userIndex + index}</td>
								<td style={{ textAlign: 'left' }}>{user.providerName}</td>
								<td>{user.clinicName}</td>
								<td>{user.email}</td>
								<td>{user.patientFirstName + ' ' + user.patientLastName}</td>
								<td>
									{new Date(user.birthDate).toLocaleDateString('en-US', {
										year: 'numeric',
										month: '2-digit',
										day: '2-digit'
									})}
								</td>

								<td style={{ textAlign: 'center' }}>
									{Object.keys(user)
										.filter(key => key.startsWith("quizResult_Data_"))
										.map(key => {
											const data = user[key];
											return data && data.productName ? data.productName : null; 
										  })
										  .filter(productName => productName !== null)
										  .join(" , ")
										}
								</td>
								<td>
									<span className='table__iconBox' style={{ justifyContent: 'center' }}>
										{/* Ref. No.: {user.assessmentrefno} */}
										<Link to={`/pdfView/${user.currentAssessmentResultId}`} className='table__button2'>
											<svg className='table__icon primary-icon' style={{ width: '2rem', height: '2rem' }}>
												<use xlinkHref={`/sprite.svg#icon-pdf`} />
											</svg>
											<span>View</span>
										</Link>
									</span>
								</td>

								{/* <td>
									<div className='table__iconBox'>
										<button
											className='table__button'
											// onClick={() => {
											// 	setmodalLoginAdminState(true);
											// 	setLoginAsUserData(user);
											// }}
											>
											<svg className='table__button--icon'>
												<use xlinkHref={`/sprite.svg#icon-login`} />
											</svg>
											<span>Login as {user.patientFirstName + ' ' + user.patientLastName}</span>
										</button>
										<button
											className='table__button'
											// onClick={() => {
											// 	setModalResetState(true);
											// 	setDeleteUserData(user);
											// }}
											>
											<svg className='table__button--icon'>
												<use xlinkHref={`/sprite.svg#icon-change_password`} />
											</svg>
											<span>Change Password</span>
										</button>

										<button
											className='table__button'
											// onClick={() => {
											// 	setModalEditState(true);
											// 	setupdateUser({
											// 		...updateUser,
											// 		userId: user.applicationUserId,
											// 		fName: user.patientFirstName,
											// 		lName: user.patientLastName,
											// 		email: user.patientEmail
											// 	});
											// }}
											>
											<svg className='table__button--icon'>
												<use xlinkHref={`/sprite.svg#icon-edit`} />
											</svg>
											<span>Edit User</span>
										</button>
										<button
											className='table__button table__button--delete'
											// onClick={() => {
											// 	setmodalDeleteState(true);
											// 	setDeleteUserData(user);
											// }}
											>
											<svg className='table__button--icon-red'>
												<use xlinkHref={`/sprite.svg#icon-delete`} />
											</svg>
											<span>Delete User</span>
										</button>
									</div>
								</td> */}
							</tr>
						))
					) : (
						<tr>
							<td colSpan='13' style={{ textAlign: 'center', padding: '2rem' }}>
								There are no quiz results to show.
							</td>
						</tr>
					) : (
						<TableRows columns='13' />
					)}
				</tbody>
			</table>
		</div>
	);
}
