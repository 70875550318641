import React, { useEffect } from 'react';
import { HashRouter as Router, Redirect, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import LoginScreen from './screens/LoginScreen';
import ForgotPassword from './screens/ForgotPassword';
import ClinicSignUp from './screens/ClinicSignUp';
import RegisterPatient from './screens/RegisterPatient';
import InviteUsers from './screens/ClinicSignUp/InviteUsers';
import Terms from './screens/ClinicSignUp/Terms';
import RegisterAdmin from './screens/RegisterAdmin/';
import RegisterClinicInitial from './screens/RegisterClinicInitial';
import RegisterAdmin1 from './screens/RegisterAdmin1';
import RegisterClinicNoInvite from './screens/RegisterClinicNoInvite/indexOLD';
import RegisterClinic from './screens/RegisterClinicNoInvite';

import Dashboard from './screens/Dashboard';
import ManageClients from './screens/ManageClients';
import HomeScreen from './screens/HomeScreen';
import PreviousAssessments from './screens/PreviousAssessments';
import InviteAdmins from './screens/InviteAdmins';
import InviteClinics from './screens/InviteClinics';
import UnverifiedUsers from './screens/UnverifiedUsers';
import ManageClinics from './screens/ManageClinics';
import MyProfile from './screens/MyProfile';
import Scheduler from './screens/Scheduler';
import ContentScreen from './screens/ContentScreen';
import Questionare from './screens/SelfAssessment/index';
import ContentHistory from './screens/ContentHistory';
import ClinicScreen from './screens/ClinicScreen';
import ClinicUser from './screens/ClinicUser';
import ManageClinicUsers from './screens/ManageClinicUsers/index';
import Documents from './screens/Documents';
import ClinicSettings from './screens/PDMPCredentials';
import VideoList from './screens/VideoList';
import VerifyEmail from './screens/VerifyEmail';
import ResetPassword from './screens/ResetPassword';

import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import PatientSummary from './screens/PatientSummary';
import { useDispatch, useSelector } from 'react-redux';
import { setUrl } from './redux/actions/ip';
import VerifyPatient from './screens/VerifyPatient';
import { logout } from './redux/actions/userActions';
import PillCountScreen from './screens/PillCountScreen';
import ConsentForms from './screens/ConsentForms';
import SubscriptionScreen from './screens/SubscriptionScreen';
import AppVersions from './screens/AppVersions';
import CustomQRCodeScreen from './screens/CustomQRCodeScreen';
import PartnerContentScreen from './screens/PartnerContentScreen';
import ConsentFormsDocument from './screens/ConsentFormsDocument';
import SummaryUploadScreen from './screens/SummaryUploadScreen';
import ModMedScreen from './screens/ModMedScreen';
import MyProfileScreen from './screens/MyProfileScreen';
import NotificationsScreen from './screens/Notifications';
import PharmacyReportSummary from './screens/PharmacyReportSummary';
import CreateQuiz from './screens/CreateQuiz';
import PatientResults from './screens/QuizSettings';
import EditQuiz from './screens/EditQuiz';
import QuizResults from './screens/QuizResults';

const App = () => {
  const dispatch = useDispatch();
  const clientURL = useSelector(state => state.clientURL);

  useEffect(() => {
    setUrl(clientURL && clientURL.url ? clientURL.url : '');
  }, [clientURL]);

  useEffect(() => {
    setUrl(clientURL && clientURL.url ? clientURL.url : '');
  }, [clientURL]);

  useEffect(() => {
    const userInfo = localStorage.getItem('userInfo');
    const tokenInfo = localStorage.getItem('token');
    const clientIdInfo = localStorage.getItem('clientId');
    const getMenusInfo = localStorage.getItem('getMenus');

    if (!userInfo) {
      localStorage.removeItem('userInfo');
      localStorage.removeItem('userInfo');
      localStorage.removeItem('token');
      localStorage.removeItem('clientId');
      localStorage.removeItem('clientUrl');
      localStorage.removeItem('getMenus');
      dispatch(logout());
    } else if (userInfo === 'undefined' || userInfo === undefined) {
      localStorage.removeItem('userInfo');
      localStorage.removeItem('userInfo');
      localStorage.removeItem('token');
      localStorage.removeItem('clientId');
      localStorage.removeItem('clientUrl');
      localStorage.removeItem('getMenus');
      dispatch(logout());
    } else if (!tokenInfo) {
      localStorage.removeItem('userInfo');
      localStorage.removeItem('userInfo');
      localStorage.removeItem('token');
      localStorage.removeItem('clientId');
      localStorage.removeItem('clientUrl');
      localStorage.removeItem('getMenus');
      dispatch(logout());
    } else if (tokenInfo === 'undefined' || tokenInfo === undefined) {
      localStorage.removeItem('userInfo');
      localStorage.removeItem('userInfo');
      localStorage.removeItem('token');
      localStorage.removeItem('clientId');
      localStorage.removeItem('clientUrl');
      localStorage.removeItem('getMenus');
      dispatch(logout());
    } else if (!clientIdInfo) {
      localStorage.removeItem('userInfo');
      localStorage.removeItem('userInfo');
      localStorage.removeItem('token');
      localStorage.removeItem('clientId');
      localStorage.removeItem('clientUrl');
      localStorage.removeItem('getMenus');
      dispatch(logout());
    } else if (clientIdInfo === 'undefined' || clientIdInfo === undefined) {
      localStorage.removeItem('userInfo');
      localStorage.removeItem('userInfo');
      localStorage.removeItem('token');
      localStorage.removeItem('clientId');
      localStorage.removeItem('clientUrl');
      localStorage.removeItem('getMenus');
      dispatch(logout());
    } else if (!getMenusInfo) {
      localStorage.removeItem('userInfo');
      localStorage.removeItem('token');
      localStorage.removeItem('clientId');
      localStorage.removeItem('clientUrl');
      localStorage.removeItem('getMenus');
      dispatch(logout());
    } else if (getMenusInfo === 'undefined' || getMenusInfo === undefined) {
      localStorage.removeItem('userInfo');
      localStorage.removeItem('token');
      localStorage.removeItem('clientId');
      localStorage.removeItem('clientUrl');
      localStorage.removeItem('getMenus');
      dispatch(logout());
    }
  }, [dispatch]);

  return (
    <Router>
      {/* Auth Routes */}
      <Route exact path='/login' component={LoginScreen} />
      <Route exact path='/' render={() => <Redirect to='/login' />} />
      <Route exact path='/terms' component={Terms} />
      <Route exact path='/invite-users' component={InviteUsers} />
      <Route exact path='/forgot-password' component={ForgotPassword} />
      <Route path='/user/reset-password' component={ResetPassword} />

      {/* Common Routes */}
      <Route exact path='/my-profile' component={MyProfile} />

      {/* Admin Routes */}
      <Route exact path='/dashboard' component={Dashboard} />
      <Route exact path='/manage-clients' component={ManageClients} />
      <Route exact path='/app-versions' component={AppVersions} />
      {/* <Route exact path='/clinic/quiz-settings' component={QuizSettings} /> */}

      {/* Candidate Results */}
      <Route exact path='/clinic/patient-quiz-results' component={QuizResults} />
      <Route exact path='/clinic/create-quiz' component={CreateQuiz} />
      <Route exact path='/clinic/edit-quiz/:quizId' component={EditQuiz} />
      <Route exact path='/clinic/quiz-settings' component={PatientResults} />

      {/* Registration Pages */}
      <Route exact path='/clinic-user' component={ClinicUser} />
      <Route exact path='/clinic-signup/:userId' component={ClinicSignUp} />
      <Route exact path='/register-admin' component={RegisterAdmin} />

      {/* <Route exact path='/register/register-clinic-initial' component={RegisterClinicInitial} /> */}
      <Route exact path='/register/register-clinic-no-invite' component={RegisterClinicNoInvite} />
      <Route exact path='/register/clinic/:token' component={RegisterClinic} />
      <Route exact path='/register/register-admin' component={RegisterAdmin1} />
      <Route exact path='/register/register-patient' component={RegisterPatient} />

      {/* User Routes */}
      <Route exact path='/user/verify-email' component={VerifyEmail} />
      <Route exact path='/user/verify/:verfiyCode' component={VerifyPatient} />

      {/* CCP Routes */}
      <Route exact path='/admin/user-manager' component={HomeScreen} />
      <Route exact path='/previous-assessments/:userId' component={PreviousAssessments} />
      <Route exact path='/pill-count-view/:userId' component={PillCountScreen} />

      <Route exact path='/invite-admins' component={InviteAdmins} />
      <Route exact path='/invite-clinics' component={InviteClinics} />
      <Route exact path='/unverified-users' component={UnverifiedUsers} />
      <Route exact path='/manage-clinics' component={ManageClinics} />

      {/* PIC Routes */}
      <Route exact path='/scheduler' component={Scheduler} />
      <Route exact path='/partner-contents' component={ContentScreen} />
      <Route exact path='/partner-category' component={PartnerContentScreen} />
      <Route exact path='/content-history' component={ContentHistory} />

      {/* Patient Routes */}
      <Route exact path='/patient/questionaire' component={Questionare} />
      <Route exact path='/pdfView/:userId' component={PatientSummary} />
      <Route exact path='/pharmacyReportView/:userId' component={PharmacyReportSummary} />

      {/* Provider Routes */}
      <Route exact path='/clinic/home' component={ClinicScreen} />
      <Route exact path='/clinic/clinic-user-list' component={ManageClinicUsers} />
      <Route exact path='/clinic/documents' component={Documents} />
      <Route exact={true} path={['/clinic/videolist/:name', '/clinic/videolist']} component={VideoList} />
      <Route exact path='/clinic/clinic-settings' component={ClinicSettings} />
      <Route exact path='/clinic/consent-forms' component={ConsentForms} />
      {/* <Route path="*" component={LoginScreen} /> */}
      <Route exact path='/subscription' component={SubscriptionScreen} />

      <Route exact path='/app' component={CustomQRCodeScreen} />

      <Route exact path='/consent-forms-documents/:userId' component={ConsentFormsDocument} />

      <Route exact path='/summary-upload' component={SummaryUploadScreen} />

      <Route exact path='/clinic/ehr-probable-match' component={ModMedScreen} />

      <Route exact path='/notifications' component={NotificationsScreen} />
      <Route exact path='/patient/my-profile' component={MyProfileScreen} />

      <ToastContainer position='bottom-right' />
    </Router>
  );
};

export default App;
