import React, { useState, useEffect, Fragment } from 'react';
import { validatePassword } from '../../utils/validators';
import './MyProfile.css';
import { DEACTIVATE_RESET, PROFILE_RESET, RESETPASSWORDS_RESET } from '../../redux/constants/userConstants';
import { useDispatch, useSelector } from 'react-redux';
import {
	profileAction,
	updateUserProfilerAction,
	deactivateAction,
	passwordResetAction,
	getAllClientAction, 
} from '../../redux/actions/userActions';
import { PersonalInfo, ResetPassword } from '../../components/Skeletons/MyProfile';
import Modal from '../../components/Modal';
import ModalHeading from '../../components/Modal/ModalHeading';
import ContentAdmin from '../../components/ContentAdmin';

import { ToggleUserSkeleton } from '../../components/Skeletons/manageUsers';
import ModalForm from '../../components/Modal/ModalForm';
import ModalTextBox from '../../components/Modal/ModalTextBox';
import { ModalButtonPrimary } from '../../components/Modal/ModalButton';
import { toast } from 'react-toastify';

const Index = ({}) => {
	const dispatch = useDispatch();

	// const userLogin = useSelector((state) => state.userLogin);
	const allClientDetails = useSelector((state) => state.allClientDetails);

	const updateUser = useSelector((state) => state.updateUser);
	const resetPassword = useSelector((state) => state.passwordData);

	const [modalActiveState, setModalActiveState] = useState(false);

	const [passwordError, setpasswordError] = useState('');
	const [ConfirmPasswordError, setConfirmPasswordError] = useState('');
	const [currentEye, setCurrentEye] = useState(false);
	const [currentNewEye, setCurrentNewEye] = useState(false);
	const [currentConfirmEye, setCurrentConfirmEye] = useState(false);

	// const [userData, setuserData] = useState();
	const [userCred, setuserCred] = useState({ userId: '', email: '', fName: '', lName: '' });
	const [resetOldPassword, setResetOldPassword] = useState({ curPassword: '', newPassword: '', confirmPassword: '' });
	const [status, setStatus] = useState();

	// useEffect(
	// 	() => {
	// 		if (!(userLogin && userLogin.userInfo && userLogin.userInfo.token)) {
	// 			history.push('/login');
	// 		} else if (!localStorage.getItem('userInfo')) {
	// 			dispatch(logout())
	// 			history.push('/login');
	// 		} else if (
	// 			userLogin &&
	// 			userLogin.userInfo
	// 		) {
	// 			setuserData({
	// 				email: '',
	// 				fName: '',
	// 				lName: ''
	// 			});
	// 		}
	// 	},
	// 	[userLogin, history]
	// );

	useEffect(
		() => {
			dispatch(profileAction());
			dispatch(getAllClientAction())
		},
		[dispatch]
	);

	useEffect(
		() => {
			if (allClientDetails && allClientDetails.userInfo)

				//setuserData({ ...allClientDetails.userInfo[0]});
				setuserCred({
					userId: allClientDetails && allClientDetails.userInfo && allClientDetails.userInfo[0].id,
					email: allClientDetails && allClientDetails.userInfo && allClientDetails.userInfo[0].email,
					fName: allClientDetails && allClientDetails.userInfo && allClientDetails.userInfo[0].firstname,
					lName: allClientDetails && allClientDetails.userInfo && allClientDetails.userInfo[0].lastname
				})
		},
		[allClientDetails]
	);
	// useEffect(
	// 	() => {
	// 		if(updateUser && !updateUser.loading){

	// 			dispatch(getAllClientAction())
	// 		}
	// 	},
	// 	[ updateUser ]
	// );

	useEffect(
		() => {
			if (resetPassword && resetPassword.userInfo && resetPassword.userInfo.succeeded === true) {
				toast.success('Password Changed Successfully')
				setResetOldPassword({ curPassword: '', newPassword: '', confirmPassword: '' })
			}
		},
		[resetPassword]
	);



	//Active User Toggle
	useEffect(
		() => {
			if (updateUser && updateUser.userInfo && updateUser.userInfo.statusCode === true) {
				dispatch(getAllClientAction());
				toast.success(updateUser.userInfo.message)
				dispatch({ type: DEACTIVATE_RESET });
				// setModalActiveState(false);
			}
		},
		[updateUser, dispatch]
	);

	const showCurrentPassword = (e) => {
		var x = document.getElementById('currentPwd');
		setCurrentEye(!currentEye);
		if (x.type === 'password') {
			x.type = 'text';
		} else {
			x.type = 'password';
		}
	};
	const showNewPassword = (e) => {
		var x = document.getElementById('newPwd');
		setCurrentNewEye(!currentNewEye);
		if (x.type === 'password') {
			x.type = 'text';
		} else {
			x.type = 'password';
		}
	};
	const showNewConfirmPassword = (e) => {
		var x = document.getElementById('confirmPwd');
		setCurrentConfirmEye(!currentConfirmEye);
		if (x.type === 'password') {
			x.type = 'text';
		} else {
			x.type = 'password';
		}
	};

	const handleSubmit = (e) => { 
		//e.preventDefault();

		const formData = {
			userId: userCred.userId,
			email: userCred.email,
			firstName: userCred.fName,
			lastName: userCred.lName
		};
		dispatch(updateUserProfilerAction(formData));
	};

	const handlePasswordSubmit = (e) => {
		//e.preventDefault();
		if (resetOldPassword.newPassword !== resetOldPassword.confirmPassword) {
			setConfirmPasswordError('Password Does not match')
		} else if (!resetOldPassword.newPassword.trim() || resetOldPassword.newPassword.trim() === '') {
			setpasswordError('Please enter a password');
		} else if (!validatePassword(resetOldPassword.newPassword)) {
			setpasswordError("Password must have at least 8 characters, 1 uppercase, 1 lowercase, and 1 special character.");
		} else if (!resetOldPassword.confirmPassword.trim() || resetOldPassword.confirmPassword.trim() === '') {
			setpasswordError('Please confirm your password');
		} else {
			setConfirmPasswordError('');
			setpasswordError('')
			const formData = {
				password: resetOldPassword.newPassword,
				userName: userCred.email
			}
			dispatch(passwordResetAction(formData));
		}

	};

	const onEditClose = () => {
		setModalActiveState(false);
	};

	const onActive = () => {
		dispatch(deactivateAction(status));
	};

	useEffect(
		() => {
			return () => {
				dispatch({ type: PROFILE_RESET });
				dispatch({ type: RESETPASSWORDS_RESET });
			};
		},
		[dispatch]
	);

	return (
		<ContentAdmin currentMenu="my-profile" headerTitle="My Profile">
			<div className="profile">
				<div className="profile__personal">
					<h2 className="profile__heading">Personal Information</h2>
					{allClientDetails && allClientDetails.loading ? (
						<div className="profile__content">
							<PersonalInfo />
						</div>
					) : (
						<div className="profile__content">
							<ModalForm>
								<div style={{ width: '100%', gap: '2rem', display: 'flex', justifyContent: 'flex-start' }}>
									<div className="modal_input" style={{ width: '100%' }}>
										<label htmlFor="email" className="modal_input--label">
											First Name
										</label>
										<div className="modal_input--box">
											<label htmlFor="email" className="modal_input--iconBox">
												<svg className="modal_input--icon">
													<use xlinkHref='/sprite.svg#icon-user' />
												</svg>
											</label>
											<input
												type='text'
												id='fName'
												placeholder='Jon'
												value={userCred.fName}
												onChange={(e) => {
													setuserCred({ ...userCred, fName: e.target.value })
													// setFNameError('');
												}}
												className="modal_input--text2"
											/>
										</div>
										{/* <p className="error_message">{fNameError}</p> */}
									</div>
									<div className="modal_input" style={{ width: '100%' }}>
										<label htmlFor="email" className="modal_input--label">
											Last Name
										</label>
										<div className="modal_input--box">
											<label htmlFor="email" className="modal_input--iconBox">
												<svg className="modal_input--icon">
													<use xlinkHref='/sprite.svg#icon-user' />
												</svg>
											</label>
											<input
												type='text'
												id='lName'
												placeholder='Doe'
												value={userCred.lName}
												onChange={(e) => {
													setuserCred({ ...userCred, lName: e.target.value })
													// setLNameError('');
												}}
												className="modal_input--text2"
											/>
										</div>
									</div>
								</div>
								<ModalTextBox
									type="text"
									icon="/sprite.svg#icon-email"
									label="Email"
									id="clientName"
									placeholder="Doe"
									disabled
									value={userCred.email}
									onChange={(e) => {
										setuserCred({ ...userCred, email: e.target.value })
										// setUserEmailError('');
									}}
									error=''
								/>
								<ModalButtonPrimary
									style={{ marginTop: '2rem' }}
									title='Save'
									onClick={handleSubmit}
								/>
							</ModalForm>
							{/* </InputsSection> */}
						</div>)}
				</div>
				<div className="profile__content2">
					{/* <InputsSection
				style={{width: '80%', height: '7rem',display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}> */}
					<label htmlFor="email" className="filter__input--label">
						Deactivate my account
					</label>
					<label className="switch">
						<input
							id="active"
							type="checkbox"
							className="checkbox"
							checked={status}
							onChange={() => {
								setModalActiveState(true);
							}}
							name="active"
						/>
						<span className="slider round" />
					</label>
					{/* </InputsSection> */}
				</div>
				<div className="profile__password">
					<h2 className="profile__heading">Change Password</h2>
					<div className="profile__content">
						{resetPassword && resetPassword.loading ? (
							<ResetPassword />
						) : (
							<form onSubmit={handlePasswordSubmit}>
								<div className="filter__input--labels">
									<div className="filter__input--labels">
										<label htmlFor="currentPwd" className="filter__input--label">
											Current Password
										</label>
										<div className="profile__input">
											<label htmlFor="currentPwd" className="filter__input--iconBox">
												<svg className="filter__input--icon">
													<use xlinkHref={`/sprite.svg#icon-key`} />
												</svg>
											</label>
											<input
												type="password" autoComplete="new-password"
												id="currentPwd"
												value={resetOldPassword.curPassword}
												onChange={(e) => {
													setResetOldPassword({ ...resetOldPassword, curPassword: e.target.value });

												}}
												placeholder="••••••••••••••"
												className="modal_input--text2"
											/>
											<svg className="filter__input--icon" onClick={showCurrentPassword}>
												{currentEye ? (
													<use xlinkHref={`/sprite.svg#icon-hidePassword`} />
												) : (
													<use xlinkHref={`/sprite.svg#icon-showPassword`} />
												)}
											</svg>
										</div>
										{/* <p className="error_message">{curPasswordError}</p> */}
									</div>
									<div className="filter__input--labels">
										<label htmlFor="newPwd" className="filter__input--label">
											New Password
										</label>
										<div className="profile__input">
											<label htmlFor="newPwd" className="filter__input--iconBox">
												<svg className="filter__input--icon">
													<use xlinkHref={`/sprite.svg#icon-key`} />
												</svg>
											</label>
											<input
												type="password" autoComplete="new-password"
												id="newPwd"
												value={resetOldPassword.newPassword}
												onChange={(e) => {
													setResetOldPassword({ ...resetOldPassword, newPassword: e.target.value });
													setpasswordError('');
												}}
												placeholder="••••••••••••••"
												className="modal_input--text2"
											/>

											<svg className="filter__input--icon" onClick={showNewPassword}>
												{currentNewEye ? (
													<use xlinkHref={`/sprite.svg#icon-hidePassword`} />
												) : (
													<use xlinkHref={`/sprite.svg#icon-showPassword`} />
												)}
											</svg>

											{/* <input type="checkbox" onClick={showNewPassword} />Show Password */}
										</div>
										<p className="error_message">{passwordError}</p>
									</div>
									<div className="filter__input--labels">
										<label htmlFor="confirmPwd" className="filter__input--label">
											Confirm Password
										</label>
										<div className="profile__input">
											<label htmlFor="confirmPwd" className="filter__input--iconBox">
												<svg className="filter__input--icon">
													<use xlinkHref={`/sprite.svg#icon-key`} />
												</svg>
											</label>
											<input
												type="password" autoComplete="new-password"
												id="confirmPwd"
												value={resetOldPassword.confirmPassword}
												onChange={(e) => {
													setResetOldPassword({ ...resetOldPassword, confirmPassword: e.target.value });
													setConfirmPasswordError('');
												}}
												placeholder="••••••••••••••"
												className="modal_input--text2"
											/>
											<svg className="filter__input--icon" onClick={showNewConfirmPassword}>
												{currentConfirmEye ? (
													<use xlinkHref={`/sprite.svg#icon-hidePassword`} />
												) : (
													<use xlinkHref={`/sprite.svg#icon-showPassword`} />
												)}
											</svg>
										</div>
										<p className="error_message">{ConfirmPasswordError}</p>
									</div>
									<button className="profile__button--password">Change Password</button>
								</div>
							</form>
						)}
					</div>
				</div>
			</div>
			<Modal show={modalActiveState}>
				<ModalHeading heading="Confirm Deactivation?" onClose={onEditClose} />
				{resetPassword && resetPassword.loading ? (
					<ToggleUserSkeleton />
				) : (
					<Fragment>
						<div className="modalContainer__form">
							<p>Are you sure you want to activate or deactivate user?</p>
						</div>
						<div className="modal__actions">
							<button className="modal__button modal__button--update" onClick={onActive}>
								Confirm
							</button>
						</div>
					</Fragment>
				)}
			</Modal>
		</ContentAdmin>
	);
};

export default Index;
