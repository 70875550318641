import {

} from '../constants/adminConstants';
import { GET_PRODUCT_CATEGORY_FAIL, GET_PRODUCT_CATEGORY_REQUEST, GET_PRODUCT_CATEGORY_RESET, GET_PRODUCT_CATEGORY_SUCCESS, GET_QUESTION_LIST_FAIL, GET_QUESTION_LIST_REQUEST, GET_QUESTION_LIST_RESET, GET_QUESTION_LIST_SUCCESS, GET_QUIZ_FILTER_RESULTS_FAIL, GET_QUIZ_FILTER_RESULTS_REQUEST, GET_QUIZ_FILTER_RESULTS_RESET, GET_QUIZ_FILTER_RESULTS_SUCCESS, GET_QUIZ_RESULTS_FAIL, GET_QUIZ_RESULTS_REQUEST, GET_QUIZ_RESULTS_RESET, GET_QUIZ_RESULTS_SUCCESS } from '../constants/quizResults';

export const getQuizResultsReducer = (state = {}, action) => {
	switch (action.type) {
		case GET_QUIZ_RESULTS_REQUEST:
			return { loading: true };

		case GET_QUIZ_RESULTS_SUCCESS:
			return { data: action.payload, loading: false };

		case GET_QUIZ_RESULTS_FAIL:
			return { loading: false, error: action.payload };

		case GET_QUIZ_RESULTS_RESET:
			return {};

		default:
			return state;
	}
};

export const getQuizFilterResultsReducer = (state = {}, action) => {
	switch (action.type) {
		case GET_QUIZ_FILTER_RESULTS_REQUEST:
			return { loading: true };

		case GET_QUIZ_FILTER_RESULTS_SUCCESS:
			return { data: action.payload, loading: false };

		case GET_QUIZ_FILTER_RESULTS_FAIL:
			return { loading: false, error: action.payload };

		case GET_QUIZ_FILTER_RESULTS_RESET:
			return {};

		default:
			return state;
	}
};

export const getQuestionListReducer  = (state = {}, action) => {
	switch (action.type) {
		case GET_QUESTION_LIST_REQUEST:
			return { loading: true };

		case GET_QUESTION_LIST_SUCCESS:
			return { data: action.payload.data, loading: false };

		case GET_QUESTION_LIST_FAIL:
			return { loading: false, error: action.payload };

		case GET_QUESTION_LIST_RESET:
			return {};

		default:
			return state;
	}
};

