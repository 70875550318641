import React from 'react';
import { Link } from 'react-router-dom';
import TableRows from '../../components/Skeletons/TableRows';
import { deleteQuiz, enableDisableQuiz } from '../../redux/actions/quizActions';
import { useDispatch } from 'react-redux';

const ResultsData = ({ showFilter, usersList, loading, data, onRefresh }) => {
	const {
		// setModalEditState,
		// setUserId,
		// setIsDeleted,
		// setModalActiveState,
		// setModalResetState,
		// setmodalDeleteState,
		// setDeleteUserData,
		// setmodalLoginAdminState,
		// setLoginAsUserData,
		// setupdateUser,
		// updateUser,
		// setRole,
		setSelectedQuizId,
		setDeleteModalState,
		setQuestionModalState,
		setSelectedProductId,
		pageNumber
	} = data;

	const dispatch = useDispatch();

	const newIndex = (
		pageNumber
		* 10) - 9;

	const onChangeHandler = async (d) => {
		await dispatch(
			enableDisableQuiz({
				quizResultId: d.quizResultId,
				isActive: !d.isActive
			})
		);
		if (onRefresh) onRefresh();
	};

	const formatCriteria = (criteria) => {
		const maxValuesLength = 40;
		return criteria.map((item, index) => {
			const title = item.title || 'Unknown';
			const valuesArray = Array.isArray(item.value) ? item.value : [];
			const values = valuesArray.join(', ');

			const truncatedValues = values.length > maxValuesLength
				? values.slice(0, maxValuesLength - 3) + '...'
				: values;

			const formattedTitle = title.replace(/([A-Z])/g, ' $1').trim();

			return (
				<div key={index}>
					<strong style={{ textTransform: 'capitalize' }}>{formattedTitle}:</strong> {truncatedValues}
				</div>
			);
		});
	};


	return (

		<div className='tableContainer' style={{ height: '63vh' }}>
			<table className='table'>
				<thead>
					<tr>
						<th>#</th>
						<th>Category</th>
						<th>Criteria</th>
						<th style={{ textAlign: 'center' }}>Frequency</th>
						<th style={{ textAlign: 'center' }}>Quiz Question Count</th>
						<th>Active</th>
						<th>Action</th>
					</tr>
				</thead>
				<tbody>
					{
						usersList && !usersList.loading ? usersList?.data?.data?.length > 0 ? (
							usersList?.data?.data?.map((user, index) => (
								<tr key={index + 1}>
									<td>{newIndex + index}</td>

									<td style={{ textAlign: 'left' }}> {user.productName}</td>
									<td>
										{formatCriteria(user.criteria)}
									</td>
									<td style={{ textAlign: 'center' }}>{user.frequency}</td>
									<td >
										<span
											className='table__button2'
											style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
											onClick={() => {
												setQuestionModalState(true);
												setSelectedProductId(user.productId);
											}
											}
										>
											{/* Prev Assessments */}
											<svg className='table__icon primary-icon'
												style={{ width: '2rem', height: '2rem' }}
											>

												<use xlinkHref={`/sprite.svg#icon-view_doc`} />
											</svg>
											<span>Criteria Questions</span>
										</span>
									</td>
									<td>
										<label className='switch'>
											<input
												id='active'
												type='checkbox'
												className='checkbox'
												checked={user.isActive}
												onChange={(e) => {
													onChangeHandler(user, e);
												}}
												name='active'
											/>
											<span className='slider round' />
										</label>
									</td>


									<td>
										<div className='table__iconBox'>
											<Link to={{pathname: `/clinic/edit-quiz/${user.quizResultId}`}}>
												<button
													className='table__button'
													onClick={() => {
														// setModalEditState(true);
														// setupdateUser({
														// 	...updateUser,
														// 	userId: user.applicationUserId,
														// 	fName: user.patientFirstName,
														// 	lName: user.patientLastName,
														// 	email: user.patientEmail,
														// 	birthDate: new Date(user.birthDate).getFullYear() + "-" + ("0" + (new Date(user.birthDate).getMonth() + 1)).slice(-2) + "-" + String(new Date(user.birthDate).getDate()).padStart(2, '0')
														// });
													}}>
													<svg className='table__button--icon'>
														<use xlinkHref={`/sprite.svg#icon-edit`} />
													</svg>
													<span>Edit Quiz</span>
												</button>
											</Link>
											<button
												className='table__button table__button--delete'
												onClick={() => {
													setSelectedQuizId(user.quizResultId);
													setDeleteModalState(true)
												}
												}>
												<svg className='table__button--icon-red'>
													<use xlinkHref={`/sprite.svg#icon-delete`} />
												</svg>
												<span>Delete Quiz</span>
											</button>
										</div>
									</td>
								</tr>
							))
						) : (
							<tr>
								<td colSpan='13'>No Quiz found</td>
							</tr>
						) : (
							<TableRows columns='13' />
						)}
				</tbody>
			</table>
		</div>
	);
};

export default ResultsData;
