import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ResetforgotPasswordAction } from '../../redux/actions/userActions';
// import './LoginScreen.css';
import { validatePassword } from '../../utils/validators';
import { UserLoginSkeleton } from '../../components/Skeletons/userLogin';
import { toast } from 'react-toastify';
import { RESET_PASSWORD_RESET } from '../../redux/constants/userConstants';

const ResetPassword = ({ history }) => {

	const dispatch = useDispatch();
	const { search } = useLocation();
	const searchParams = new URLSearchParams(search)
	const userName = searchParams.get('user')

	const [newPassword, setNewPassword] = useState('');
	const [emailError, setEmailError] = useState('');
	const [password, setPassword] = useState('');
	const [currentEye, setCurrentEye] = useState(false);
	const [passwordEye, setPasswordEye] = useState(false);
	const [passwordError, setPasswordError] = useState('');

	const userLogin = useSelector((state) => state.userLogin);
	const resetPassword = useSelector((state) => state.resetPassword);

	useEffect(() => {
		if (resetPassword && resetPassword.data && resetPassword.data.succeeded) {
			toast.success('Password Changed Successfully')
			history.push('/login')
		} else if (resetPassword && resetPassword.error) {
			toast.error(resetPassword.error)
		}
	}, [resetPassword, history])

	const showConfirmPassword = () => {
		var x = document.getElementById('confirm-password');
		setCurrentEye(!currentEye);
		if (x.type === 'password') {
			x.type = 'text';
		} else {
			x.type = 'password';
		}
	};
	const showPassword = () => {
		var x = document.getElementById('password');
		setPasswordEye(!passwordEye);
		if (x.type === 'password') {
			x.type = 'text';
		} else {
			x.type = 'password';
		}
	};
	const handleSubmit = (e) => {
		e.preventDefault();
		if (newPassword !== password) {
			setPasswordError("Password does not match")
		} else if (!newPassword.trim() || newPassword.trim() === '') {
			setEmailError('Please enter your new password');
		} else if (!validatePassword(newPassword)) {
			setPasswordError("Password must have at least 8 characters, 1 uppercase, 1 lowercase, and 1 special character.");
		} else if (!password || password.trim() === '') {
			setPasswordError('Please confirm password');
		} else {
			const formData = {
				Password: newPassword,
				UserName: userName
			}
			dispatch(ResetforgotPasswordAction(formData));
		}
	};
	useEffect(
		() => {
			return () => {
				dispatch({ type: RESET_PASSWORD_RESET });
			};
		},
		[dispatch]
	);

	return (
		<main className="loginContainer">
			<div className="loginContainer__bg" />
			<div className="loginContainer__box">
				<div className="loginContainer__box--right">
					<img src="/images/logo.png" alt="Suremed Logo" className="loginContainer__box--logo" />
					<h1 className="loginContainer__heading">RESET YOUR PASSWORD</h1>
				</div>
				<div className="loginContainer__box--left">
					{userLogin && userLogin.loading ? (
						<UserLoginSkeleton />
					) : (
						<form className="loginContainer__form" onSubmit={handleSubmit}>
							<div className="loginContainer__formgroup" >
								<label htmlFor="email" className="loginContainer__formgroup--label">
									New Password
								</label>
								<div className="loginContainer__formgroup--inputBox">
									<div className="logoContainer__formgroup--iconBox">
										<svg className="loginContainer__formgroup--icon">
											<use xlinkHref="/sprite.svg#icon-key" />
										</svg>
									</div>
									<input
										type="password"  autoComplete="new-password"
										placeholder="•••••••••••"
										id="password"
										value={newPassword}
										onChange={(e) => {
											setEmailError('');
											setPasswordError('')
											setNewPassword(e.target.value);
										}}
										className="loginContainer__formgroup--input"
									/>
									<svg
										style={{ margin: '1rem' }}
										className="loginContainer__formgroup--icon"
										onClick={showPassword}
									>
										{passwordEye ? (
											<use xlinkHref={`/sprite.svg#icon-hidePassword`} />
										) : (
											<use xlinkHref={`/sprite.svg#icon-showPassword`} />
										)}
									</svg>
								</div>
								<p className="error_message">{emailError}</p>
							</div>
							<div className="loginContainer__formgroup">
								<label htmlFor="password" className="loginContainer__formgroup--label">
									Confirm Password
								</label>
								<div className="loginContainer__formgroup--inputBox">
									<div className="logoContainer__formgroup--iconBox">
										<svg className="loginContainer__formgroup--icon">
											<use xlinkHref="/sprite.svg#icon-key" />
										</svg>
									</div>
									<input
										type="password"  autoComplete="new-password"
										placeholder="•••••••••••"
										id="confirm-password"
										value={password}
										onChange={(e) => {
											setPasswordError('');
											setPassword(e.target.value);
										}}
										className="loginContainer__formgroup--input"
									/>

									<svg
										style={{ margin: '1rem' }}
										className="loginContainer__formgroup--icon"
										onClick={showConfirmPassword}
									>
										{currentEye ? (
											<use xlinkHref={`/sprite.svg#icon-hidePassword`} />
										) : (
											<use xlinkHref={`/sprite.svg#icon-showPassword`} />
										)}
									</svg>
								</div>
								<p className="loginError error_message">{passwordError}</p>
							</div>
							<div className="loginContainer__formgroup--btn">
								{userLogin && userLogin.loading ? (
									<div className="loginContainer__button">
										<span className="loginContainer__button--text">Resetting</span>
										<div className="loginContainer__button--iconBox">
											<svg className="loginContainer__button--icon">
												<use xlinkHref="/sprite.svg#icon-login" />
											</svg>
										</div>
									</div>
								) : (
									<button className="loginContainer__button" onClick={handleSubmit}>
										<span className="loginContainer__button--text">Reset</span>
										<div className="loginContainer__button--iconBox">
											<svg className="loginContainer__button--icon">
												<use xlinkHref="/sprite.svg#icon-login" />
											</svg>
										</div>
									</button>
								)}
							</div>
						</form>
					)}
				</div>
			</div>
		</main>
	);
};

export default ResetPassword;
