import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { validateEmail, validatePassword } from '../../utils/validators';
import './documents.css'

import { clinicProvidersAction, newInviteAction, termsAcceptAction } from '../../redux/actions/providerActions'
import { AddInvite } from './AddNewInvite';
import ShowData from './ShowData';
import Content from '../../components/Content';
import { ADD_INVITE_RESET, CLINIC_PROVIDER_RESET, TERMS_ACCEPT_RESET } from '../../redux/constants/providerConstants';
import { ModalButtonPrimary } from '../../components/Modal/ModalButton';
import DemoVideoModal from './DemoVideoModal';
import { addDocumentHistoryAction, baaPdfAction, logout } from '../../redux/actions/userActions';
import DocTerms from './DocTerms';
import User_Guide_PDF from "../../screens/Documents/PDF/Perspectives_in_Care_User_Guide.pdf";
import office_sign_doc from "../../screens/Documents/PDF/Office-Sign-WORD.docx";
import app_instructions_doc from "../../screens/Documents/PDF/App_Instructions.pdf";
import axios from 'axios';

function Index({ history }) {
    const dispatch = useDispatch();

    const userLogin = useSelector((state) => state.userLogin.userInfo);
    const clinicProvider = useSelector((state) => state.clinicProvider);
    const addInviteProvider = useSelector((state) => state.addInviteProvider);
    const termsAccept = useSelector((state) => state.termsAccept);

    const [addModalState, setAddModalState] = useState(false);
    const [termsState, setTermsState] = useState(false);
    const [videoModalState, setVideoModalState] = useState(false);
    const [showBack, setShowBack] = useState(false);

    const [inviteData, setInviteData] = useState({
        credentials: "",
        displayAsClinic: false,
        email: "",
        firstName: "",
        gender: "",
        id: "",
        isAdmin: false,
        isProvider: true,
        lastName: "",
        npi: "",
        password: "",
        confirmPassword: '',
        shownAs: "",
        validDEACert: false,
        pdmpEmail: "",
        pdmpPassword: "",
        pdmpConfirmPassword: "",
        pdmpState: ""

    });

    const [inviteDataError, setInviteDataError] = useState({
        credentials: "",
        displayAsClinic: false,
        email: "",
        firstName: "",
        gender: "",
        id: "",
        isAdmin: false,
        isProvider: false,
        lastName: "",
        npi: "",
        password: "",
        shownAs: "",
        validDEACert: false,
    });


    useEffect(() => {
        if (addInviteProvider && addInviteProvider.data && addInviteProvider.data.statusCode === true) {
            dispatch(clinicProvidersAction())
            setAddModalState(false);
            toast.success(addInviteProvider.data.message);
            setInviteData({
                credentials: "", displayAsClinic: false, email: "", firstName: "", gender: "", id: "", isAdmin: false, isProvider: false, lastName: "",
                npi: "", password: "", shownAs: "", validDEACert: false,
            });
        } else if (addInviteProvider && addInviteProvider.data && addInviteProvider.data.statusCode === false) {
            setAddModalState(false);
            toast.error(addInviteProvider.data.message);
        } else if (addInviteProvider && addInviteProvider.error) {
            setAddModalState(false)
            toast.error(addInviteProvider.error);
        }
    }, [addInviteProvider, dispatch])

    useEffect(() => {
        if (termsAccept && termsAccept.data && termsAccept.data.isTermsAccepted) {
            setTermsState(false)
            toast.success('Terms of Service Accepted')
        } else if (termsAccept && termsAccept.error) {
            setTermsState(false)
            toast.error(termsAccept.error.message)
        }
    }, [termsAccept])


    const addBtnClick = () => {
        setAddModalState(true);
    };
    const handleOnPlay = () => {
        var formData = {
            isvideo: true,
            isstart: true
        };
        dispatch(addDocumentHistoryAction(formData))
    }
    const onTermsClose = () => {
        setTermsState(false)
        dispatch(logout())
        history.push('/login')
    }
    const onAccept = (e) => { 
        const formData = {
            isaccepted: true
        }
        dispatch(termsAcceptAction(formData))
    }

    const handleOnPause = () => {
        var formData = {
            isvideo: true,
            isstart: false
        };
        dispatch(addDocumentHistoryAction(formData))
    };
    const handleOnEnded = () => {
        var formData = {
            isvideo: true,
            isstart: false
        };
        dispatch(addDocumentHistoryAction(formData))
        setVideoModalState(false);
    };
    const onVideoClose = () => {
        var formData = {
            isvideo: true,
            isstart: false
        };
        dispatch(addDocumentHistoryAction(formData))
        setVideoModalState(false)
    }
    const handleUserGuide = () => {
        // window.open(UserGuide)
        window.open("https://www.suremedcompliance.com/userguide")
    }
    const PDFhandleUserGuide = (pdf) => {
        window.open(pdf)
    }

    const DochandleUserGuide = () => {
        window.open(office_sign_doc)
    }

    const onCloseAddModal = () => {
        setAddModalState(false);
        setInviteData({
            ...inviteData,
            credentials: "",
            displayAsClinic: false,
            email: "",
            firstName: "",
            gender: "",
            id: "",
            isAdmin: false,
            isProvider: true,
            lastName: "",
            npi: "",
            password: "",
            shownAs: "",
            validDEACert: false,
        });
    };

    const handleProvider = () => {
        dispatch(clinicProvidersAction())
    }
    const handleAddInvite = () => { 
        if (!inviteData.email.trim() || inviteData.email.trim() === '') {
            setInviteDataError({ ...inviteDataError, email: 'Please enter Email' });
        } else if (!validateEmail(inviteData.email)) {
            setInviteDataError({ ...inviteDataError, email: 'Please enter a valid email' });
        } else if (!inviteData.password.trim() || inviteData.password.trim() === '') {
            setInviteDataError({ ...inviteDataError, password: 'Please enter a password' });
        } else if (!validatePassword(inviteData.password)) {
            setInviteDataError({ ...inviteDataError, password: 'Password must have at least 8 characters, 1 uppercase, 1 lowercase, and 1 special character.' });
        } else if (!inviteData.confirmPassword.trim() || inviteData.confirmPassword.trim() === '') {
            setInviteDataError({ ...inviteDataError, confirmPassword: 'Please confirm you Password' });
        } else if (inviteData.confirmPassword !== inviteData.password) {
            setInviteDataError({ ...inviteDataError, confirmPassword: 'Passwords does not match.' });
        } else if (!inviteData.firstName.trim() || inviteData.firstName.trim() === '') {
            setInviteDataError({ ...inviteDataError, firstName: 'Please enter First Name' });
        } else if (!inviteData.lastName.trim() || inviteData.lastName.trim() === '') {
            setInviteDataError({ ...inviteDataError, lastName: 'Please enter Last Name' });
        } else if (inviteData.isAdmin === false && inviteData.isProvider === false) {
            setInviteDataError({ ...inviteDataError, isAdmin: 'Please select atleast one User Role' })
        } else {
            dispatch(newInviteAction(inviteData))
        }
    }

    useEffect(
        () => {
            return () => {
                dispatch({ type: ADD_INVITE_RESET });
                dispatch({ type: CLINIC_PROVIDER_RESET });
                dispatch({ type: TERMS_ACCEPT_RESET });
            };
        },
        [dispatch]
    );


    const handleBack = () => {
        dispatch({
            type: CLINIC_PROVIDER_RESET
        })
    }

  const [userDetails, setUserDetails] = useState({});

    useEffect(() => {
        if (userLogin && userLogin.token) {
            getUserDetails();
        }
    }, [userLogin]);


    const getUserDetails = () => {
        axios
            .get(`${userLogin.apiurl}/auth/support/getuserdetails`, {
                headers: {
                    Authorization: `Bearer ${userLogin.token}`,
                    "custom-clinicid-header": userLogin.clinicid,
                },
            })
            .then((res) => {
                let userData = res?.data;
                console.log(userData,'userData');
                setUserDetails(userData); 
            })
            .catch((err) => console.log(err));
    };


    const getClinicBAA = async () => {
        const data = await dispatch(baaPdfAction(userDetails.clinicId))
        if (data && data.status) {
            window.open(data.pdfURL)
        } else {
            alert("Invalid Clinic Id");
        }
    };

    return (
        <Content
            current="documents"
            headerTitle="Documents"
            currentMenu='Documents'
            addBtn={false}
            addBtnText="Add New Invite"
            addBtnIcon="/sprite.svg#icon-plus"
            addBtnClick={addBtnClick}
            showBack={false}
            onBack={handleBack}
        >
            <DemoVideoModal
                data={{ videoModalState, onVideoClose, handleOnPlay, handleOnPause, handleOnEnded }}
            />
            <DocTerms
                data={{ termsState, onTermsClose, onAccept }}
            />
            {
                clinicProvider && clinicProvider.data ?
                    <ShowData setShowBack={setShowBack} data={{ clinicProvider }} />
                    :
                    // <div className='document_landingPage' onClick={handleProvider}>
                    //     <h3>Patient Welcome Letter</h3>
                    // </div>
                    <div
                        //style={{marginTop: '2rem', display: 'flex', gap: '2rem'}}
                        className='documents--button--Container'>
                        <ModalButtonPrimary title='Patient Welcome Letter'
                            onClick={handleProvider} />
                        {/* <ModalButtonPrimary title='Demo Video'
                            onClick={() => setVideoModalState(true)} /> */}
                        <ModalButtonPrimary title='Terms of Services'
                            onClick={() => setTermsState(true)} />
                        <ModalButtonPrimary title='Web User Guide'
                            onClick={handleUserGuide}
                        />
                        <ModalButtonPrimary title='PDF User Guide'
                            onClick={() => PDFhandleUserGuide(User_Guide_PDF)}
                        />
                        <ModalButtonPrimary title='Office Sign'
                            onClick={DochandleUserGuide}
                        />
                        <ModalButtonPrimary title='App Instructions'
                            onClick={() => PDFhandleUserGuide(app_instructions_doc)}
                        />

                        <ModalButtonPrimary title='BAA Document'
                            onClick={getClinicBAA}
                        /> 
                    </div>
            }
            <AddInvite
                data={{
                    userLogin,
                    addModalState,
                    onCloseAddModal,
                    handleAddInvite,
                    inviteData,
                    setInviteData,
                    inviteDataError,
                    setInviteDataError
                }}
            />
            {/* <AddNewInvite
                data={{
                    addModalState,
                    onCloseAddModal,
                    handleAddInvite,
                    inviteData,
                    setInviteData,
                    inviteDataError,
                    setInviteDataError
                }}
            /> */}


        </Content>
    )
}
export default Index
