import React, { Fragment } from 'react';
import Modal from '../../components/Modal';
import ModalHeading from '../../components/Modal/ModalHeading';
import { InputsSection, InputsSection2 } from '../../components/Modal/InputsSection';
import ModalForm from '../../components/Modal/ModalForm';
import { ModalButtonPrimary } from '../../components/Modal/ModalButton';
import ModalTextBox from '../../components/Modal/ModalTextBox';
import ModalCheckBox from '../../components/Modal/ModalCheckBox';
import { ToggleUserSkeleton } from '../../components/Skeletons/manageUsers';

export const AddNewProvider = ({ data }) => {
	const {
		userLogin,
		modalAddState,
		onCloseAddModal,
		handleAddInvite,
		newUserData,
		setNewUserData,
		newUserDataError,
		setNewUserDataError
	} = data;
	return (
		<Modal
			show={modalAddState}
		>
			<ModalHeading heading="Add Provider"
				onClose={onCloseAddModal}
			/>
			<ModalForm onSubmit={handleAddInvite} style={{ marginBottom: '2.5rem', height: '60vh', overflow: 'auto' }}>
				<div style={{}}>
					<InputsSection style={{ display: 'flex', flexDirection: 'column', gap: '2rem' }}>
						<ModalTextBox
							type="email"
							icon="/sprite.svg#icon-email"
							label="Email Address"
							id="Email"
							placeholder="email"
							style={{ width: '45rem' }}
							value={newUserData.email}
							onChange={(e) => {
								setNewUserData({ ...newUserData, email: e.target.value })
								setNewUserDataError({ ...newUserDataError, email: '' })
							}}
							error={newUserDataError.email}
						/>
						<div style={{ display: 'flex', gap: '2rem', justifyContent: 'flex-end' }}>
							<ModalTextBox
								type="password" autoComplete="new-password"
								icon="/sprite.svg#icon-key"
								label="New Provider Password"
								id="password"
								placeholder="****"
								value={newUserData.password}
								onChange={(e) => {
									setNewUserData({ ...newUserData, password: e.target.value })
									setNewUserDataError({ ...newUserDataError, password: '' })
								}} 
							/>
							<ModalTextBox
								type="password" autoComplete="new-password"
								icon="/sprite.svg#icon-key"
								label="Confirm Password"
								id="ConfirmPassword"
								placeholder="****"
								value={newUserData.confirmPassword}
								onChange={(e) => {
									setNewUserData({ ...newUserData, confirmPassword: e.target.value })
									setNewUserDataError({ ...newUserDataError, confirmPassword: '' })
								}}
								error={newUserDataError.confirmPassword}
							/>
						</div>
						<p className='error_message'>{newUserDataError.password}</p>
						<p style={{ color: 'var(--light-dark)' }}>Password must have at least 8 characters, 1 uppercase, 1 lowercase, and 1 special character</p>
						<div style={{ display: 'flex', gap: '2rem' }}>
							<ModalTextBox
								type="text"
								icon="/sprite.svg#icon-user"
								label="First Name"
								id="clientName"
								placeholder="Jon"
								value={newUserData.firstName}
								onChange={(e) => {
									setNewUserData({ ...newUserData, firstName: e.target.value })
									setNewUserDataError({ ...newUserDataError, firstName: '' })
								}}
								error={newUserDataError.firstName}
							/>
							<ModalTextBox
								type="text"
								icon="/sprite.svg#icon-user"
								label="Last Name"
								id="providerName"
								placeholder="Doe"
								value={newUserData.lastName}
								onChange={(e) => {
									setNewUserData({ ...newUserData, lastName: e.target.value })
									setNewUserDataError({ ...newUserDataError, lastName: '' })
								}}
								error={newUserDataError.lastName}
							/>
						</div>

					</InputsSection>

					<InputsSection style={{ display: 'flex', flexDirection: 'column' }}>
						<div style={{ width: '100%', display: 'flex', justifyContent: 'space-evenly' }}>
							<ModalCheckBox
								label='Admin'
								checked={newUserData.isAdmin}
								value={newUserData.isAdmin}
								onChange={() => {
									setNewUserData({ ...newUserData, isAdmin: !newUserData.isAdmin })
									setNewUserDataError({ ...newUserDataError, isAdmin: '' })
								}}
							/>
							<ModalCheckBox
								label='Provider'
								checked={newUserData.isProvider}
								value={newUserData.isProvider}
								onChange={() => {
									setNewUserData({ ...newUserData, isProvider: !newUserData.isProvider })
									setNewUserDataError({ ...newUserDataError, isAdmin: '' })
								}}
							/> 
						</div>
						<p className='error_message__deleteClient'>{newUserDataError.isAdmin}</p>


						{newUserData.isProvider ?
							<div style={{ display: 'flex', gap: '2rem' }}>
								<ModalTextBox
									type="text"
									icon="/sprite.svg#icon-menu"
									label="NPI Number"
									id="clientName"
									placeholder="NPI Number"
									value={newUserData.npi}
									onChange={(e) => setNewUserData({ ...newUserData, npi: e.target.value })}
								/>
								<ModalTextBox
									type="text"
									icon="/sprite.svg#icon-status"
									label="Credential"
									id="providerName"
									placeholder="Enter Credential"
									value={newUserData.credentials}
									onChange={(e) => setNewUserData({ ...newUserData, credentials: e.target.value })}
								/>
							</div>
							:
							<div style={{ display: 'flex', gap: '2rem' }}>
								<ModalTextBox
									style={{ backgroundColor: 'var(--placeholder)' }}
									type="text"
									disabled
									icon="/sprite.svg#icon-menu"
									label="NPI Number"
									id="clientName"
									//placeholder="Jon"
									value={newUserData.npi}
									onChange={(e) => setNewUserData({ ...newUserData, npi: e.target.value })}
								/>
								<ModalTextBox
									style={{ backgroundColor: 'var(--placeholder)' }}
									type="text"
									disabled
									icon="/sprite.svg#icon-status"
									label="Credential"
									id="Credential"
									//placeholder="Doe"
									value={newUserData.credentials}
									onChange={(e) => setNewUserData({ ...newUserData, credentials: e.target.value })}
								/>
							</div>}
						{
							newUserData.isProvider ?
								<div style={{ display: 'flex', gap: '2rem', alignItems:'center' }}>
									<div className='modal_input' >
										<label htmlFor="email" className="modal_input--label">
											Gender
										</label>
										<div className='modal_input--box' >
											<label htmlFor="email" className="modal_input--iconBox">
												<svg className="modal_input--icon">
													<use xlinkHref={`/sprite.svg#icon-user`} />
												</svg>
											</label>
											<select
												className='filter__input--select'
												value={newUserData.gender}
												onChange={(e) => setNewUserData({ ...newUserData, gender: e.target.value })}
												name='providers'
												placeholder='Select Gender'
												id='providers'>
												<option value={null}></option>
												<option value='Male'>Male</option>
												<option value='Female'>Female</option>
												<option value='Other'>Other</option>
											</select>
										</div>
									</div>

									<ModalCheckBox
										label='Valid DEA Certificate'
										checked={newUserData.validDEACert}
										value={newUserData.validDEACert}
										onChange={() => {
											setNewUserData({ ...newUserData, validDEACert: !newUserData.validDEACert })
										}
										}
									/>
								</div>
								:
								<div style={{ display: 'flex', gap: '2rem'}}>
									<div className='modal_input' >
										<label htmlFor="email" className="modal_input--label">
											Gender
										</label>
										<div className='modal_input--box' style={{backgroundColor: 'var(--placeholder)' }}>
											<label htmlFor="email" className="modal_input--iconBox">
												<svg className="modal_input--icon">
													<use xlinkHref={`/sprite.svg#icon-user`} />
												</svg>
											</label>
											<select
												disabled
												className='filter__input--select'
												value={newUserData.gender}
												placeholder='Select Gender'
												onChange={(e) => setNewUserData({ ...newUserData, gender: e.target.value })}
												name='providers'
												id='providers'>
												<option value={null}></option>
												<option value='Male'>Male</option>
												<option value='Female'>Female</option>
												<option value='Other'>Other</option>
											</select>
										</div>
									</div>
									<ModalCheckBox
										disabled
										label='Valid DEA Certificate'
										checked={newUserData.validDEACert}
										value={newUserData.validDEACert}
										onChange={() => {
											setNewUserData({ ...newUserData, validDEACert: !newUserData.validDEACert })
										}
										}
									/>
								</div>}
					</InputsSection>
				</div>
				<div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
					<ModalButtonPrimary title="Submit" onClick={handleAddInvite} />
				</div>
			</ModalForm>
		</Modal>
	);
}

export const EditProvider = ({ data }) => {
	const {
		userLogin,
		modalEditState,
		onCloseEditModal,
		handleEditInvite,
		newUserData,
		setNewUserData,
	} = data;

	return (
		<Modal
			show={modalEditState}
		>
			<ModalHeading heading="Edit Provider"
				onClose={onCloseEditModal}
			/>
			<ModalForm style={{ marginBottom: '2.5rem', height: '60vh', overflow: 'auto' }}>
				<div style={{}}>
					<InputsSection style={{ display: 'flex', flexDirection: 'column', gap: '2rem', justifyContent: 'center' }}>
						<div>
							<ModalTextBox
								type="email"
								disabled
								style={{ width: '45rem' }}
								icon="/sprite.svg#icon-email"
								label="Email Address"
								id="Email"
								placeholder="email"
								value={newUserData.email}
								onChange={(e) => { setNewUserData({ ...newUserData, email: e.target.value }) }}
							/>
						</div>
						<div style={{ display: 'flex', gap: '2rem' }}>
							<ModalTextBox
								type="text"
								icon="/sprite.svg#icon-user"
								label="First Name"
								id="clientName"
								placeholder="Jon"
								value={newUserData.firstName}
								onChange={(event) => {
									setNewUserData({ ...newUserData, firstName: event.target.value })
									// setAddDataError({...addDataError, clientname: '' })
								}}
							/>
							<ModalTextBox
								type="text"
								icon="/sprite.svg#icon-user"
								label="Last Name"
								id="providerName"
								placeholder="Doe"
								value={newUserData.lastName}
								onChange={(event) => {
									setNewUserData({ ...newUserData, lastName: event.target.value })
								}}
							/>
						</div>
					</InputsSection>
					<InputsSection style={{ display: 'flex', flexDirection: 'column' }}>
						<div style={{ width: '100%', display: 'flex', justifyContent: 'space-evenly' }}>
							<ModalCheckBox
								label='Admin'
								checked={newUserData.isAdmin}
								value={newUserData.isAdmin}
								onChange={() => {
									setNewUserData({ ...newUserData, isAdmin: !newUserData.isAdmin })
									// setNewUserDataError({...newUserDataError, isAdmin: '' })
								}}
							/>
							<ModalCheckBox
								label='Provider'
								checked={newUserData.isProvider}
								value={newUserData.isProvider}
								onChange={() => {
									setNewUserData({ ...newUserData, isProvider: !newUserData.isProvider })
									// setNewUserDataError({...newUserDataError, isAdmin: '' })
								}}
							/>
						</div>
						{newUserData.isProvider ?
							<div style={{ display: 'flex', gap: '2rem' }}>
								<ModalTextBox
									type="text"
									icon="/sprite.svg#icon-menu"
									label="NPI Number"
									id="clientName"
									//placeholder="Jon"
									value={newUserData.npi}
									onChange={(e) => setNewUserData({ ...newUserData, npi: e.target.value })}
								/>
								<ModalTextBox
									type="text"
									icon="/sprite.svg#icon-status"
									label="Credential"
									id="providerName"
									//placeholder="Doe"
									value={newUserData.credentials}
									onChange={(e) => setNewUserData({ ...newUserData, credentials: e.target.value })}
								/>
							</div>
							:
							<div style={{ display: 'flex', gap: '2rem' }}>
								<ModalTextBox
									disabled
									style={{ backgroundColor: 'var(--placeholder)' }}
									type="text"
									icon="/sprite.svg#icon-menu"
									label="NPI Number"
									id="clientName"
									//placeholder="Jon"
									value={newUserData.npi}
									onChange={(e) => setNewUserData({ ...newUserData, npi: e.target.value })}
								/>
								<ModalTextBox
									disabled
									style={{ backgroundColor: 'var(--placeholder)' }}
									type="text"
									icon="/sprite.svg#icon-status"
									label="Credential"
									id="providerName"
									//placeholder="Doe"
									value={newUserData.credentials}
									onChange={(e) => setNewUserData({ ...newUserData, credentials: e.target.value })}
								/>
							</div>}
						{newUserData.isProvider ?
							<div style={{ display: 'flex', gap: '2rem', width: '90%' }}>
								<div className='modal_input' >
									<label htmlFor="email" className="modal_input--label">
										Gender
									</label>
									<div className='modal_input--box' style={{ width: '21rem' }}>
										<label htmlFor="email" className="modal_input--iconBox">
											<svg className="modal_input--icon">
												<use xlinkHref={`/sprite.svg#icon-user`} />
											</svg>
										</label>
										<select
											className='filter__input--select'
											value={newUserData.gender}
											onChange={(e) => setNewUserData({ ...newUserData, gender: e.target.value })}
											name='providers'
											id='providers'>
											<option value={null}></option>
											<option value='Male'>Male</option>
											<option value='Female'>Female</option>
											<option value='Other'>Other</option>
										</select>
									</div>
								</div>
								<ModalCheckBox
									label='Valid DEA Certificate'
									checked={newUserData.validDEACert}
									value={newUserData.validDEACert}
									onChange={() => {
										setNewUserData({ ...newUserData, validDEACert: !newUserData.validDEACert })
									}
									}
								/>
							</div>
							:
							<div style={{ display: 'flex', gap: '2rem', width: '90%' }}>
								<div className='modal_input' >
									<label htmlFor="email" className="modal_input--label">
										Gender
									</label>
									<div className='modal_input--box' style={{ width: '21rem', backgroundColor: 'var(--placeholder)' }}>
										<label htmlFor="email" className="modal_input--iconBox">
											<svg className="modal_input--icon">
												<use xlinkHref={`/sprite.svg#icon-user`} />
											</svg>
										</label>
										<select
											className='filter__input--select'
											value={newUserData.gender}
											onChange={(e) => setNewUserData({ ...newUserData, gender: e.target.value })}
											name='providers'
											id='providers'>
											<option value={null}></option>
											<option value='Male'>Male</option>
											<option value='Female'>Female</option>
											<option value='Other'>Other</option>
										</select>
									</div>
								</div>
								<ModalCheckBox
									disabled
									label='Valid DEA Certificate'
									checked={newUserData.validDEACert}
									value={newUserData.validDEACert}
									onChange={() => {
										setNewUserData({ ...newUserData, validDEACert: !newUserData.validDEACert })
									}
									}
								/>
							</div>}
					</InputsSection>
				</div>
				<ModalButtonPrimary title="Submit" onClick={handleEditInvite} />
			</ModalForm>
		</Modal>
	)
}

export const EditModal = ({ data }) => {
	const {
		modalEditState,
		onCloseEditModal,
		handleAddInvite,
		newUserData,
		setNewUserData
	} = data;

	return (
		<Modal
			show={modalEditState}
		>
			<ModalHeading heading="Edit Provider"
				onClose={onCloseEditModal}
			/>
			{/* {
				addClient && addClient.loading?
				<AddClientSkeleton/>
				: */}
			<ModalForm onSubmit={handleAddInvite} style={{ marginBottom: '2.5rem', height: '60vh', overflow: 'auto' }}>
				<InputsSection2>
					<ModalTextBox
						type="text"
						icon="/sprite.svg#icon-email"
						label="Email Address"
						id="Email"
						placeholder="email"
						value={newUserData.email}
						onChange={(e) => {
							setNewUserData({ ...newUserData, email: e.target.value })
							// setNewUserDataError({...newUserDataError, email: '' })
						}}
					/>
					{/* <p className='error_message__deleteClient'>{addDataError.emailaddress}</p> */}

				</InputsSection2>

				<InputsSection
					style={{
						display: 'flex',
						flexDirection: 'column'
					}}
				>
					<div style={{ width: '100%', display: 'flex', justifyContent: 'space-evenly' }}>
						<ModalCheckBox
							label='Admin'
							checked={newUserData.isAdmin}
							value={newUserData.isAdmin}
							onChange={() => {
								setNewUserData({ ...newUserData, isAdmin: !newUserData.isAdmin })
								// setNewUserDataError({...newUserDataError, isAdmin: '' })
							}}
						/>
						<ModalCheckBox
							label='Provider'
							checked={newUserData.isProvider}
							value={newUserData.isProvider}
							onChange={() => {
								setNewUserData({ ...newUserData, isAdmin: !newUserData.isAdmin })
								// setNewUserDataError({...newUserDataError, isAdmin: '' })
							}}
						/>
					</div>
					<div style={{
						display: 'flex',
						justifyContent: 'flex-start',
						gap: '2rem'
					}}>
						<div style={{}}>
							<ModalTextBox
								type="text"
								icon="/sprite.svg#icon-name"
								label="First Name"
								id="clientName"
								placeholder="Jon"
								value={newUserData.firstName}
								onChange={(event) => {
									setNewUserData({ ...newUserData, firstName: event.target.value })
									// setAddDataError({...addDataError, clientname: '' })
								}}
							/>

							{/* <p className='error_message__deleteClient'>{addDataError.clientname}</p> */}
						</div>

						<div style={{}}>
							<ModalTextBox
								type="text"
								icon="/sprite.svg#icon-provider"
								label="Last Name"
								id="providerName"
								placeholder="Doe"
								value={newUserData.lastName}
								onChange={(event) => {
									setNewUserData({ ...newUserData, lastName: event.target.value })
								}}
							/>
							{/* <p className='error_message__deleteClient'>{addDataError.providername}</p> */}
						</div>
					</div>
					<div style={{
						display: 'flex',
						justifyContent: 'flex-start',
						gap: '2rem'
					}}>
						<div style={{}}>
							<ModalTextBox
								type="text"
								icon="/sprite.svg#icon-name"
								label="NPI Number"
								id="clientName"
								placeholder="Jon"
								value={newUserData.npi}
								onChange={(event) => {
									setNewUserData({ ...newUserData, npi: event.target.value })
								}}
							/>

							{/* <p className='error_message__deleteClient'>{addDataError.clientname}</p> */}
						</div>

						<div style={{}}>
							<ModalTextBox
								type="text"
								icon="/sprite.svg#icon-provider"
								label="Credential"
								id="providerName"
								placeholder="Doe"
								value={newUserData.credentials}
								onChange={(event) => {
									setNewUserData({ ...newUserData, credentials: event.target.value })
								}}
							/>
							{/* <p className='error_message__deleteClient'>{addDataError.providername}</p> */}
						</div>
					</div>
					<div style={{
						display: 'flex',
						justifyContent: 'flex-start',
						gap: '2rem',
						width: '90%'
					}}>
						<div className='modal_input'>
							<label htmlFor="email" className="modal_input--label">
								Gender
							</label>
							<div className='modal_input--box' style={{ width: '22rem' }}>
								<label htmlFor="email" className="modal_input--iconBox">
									<svg className="modal_input--icon">
										<use xlinkHref={`/sprite.svg#icon-user`} />
									</svg>
								</label>
								<select
									className='filter__input--select'
									value={newUserData.gender}
									onChange={(event) => {
										setNewUserData({ ...newUserData, gender: event.target.value })
									}}
									name='providers'
									id='providers'>
									<option value={null}></option>
									<option value='Male'>Male</option>
									<option value='Female'>Female</option>
									<option value='Other'>Other</option>
								</select>
							</div>
						</div>
						<ModalCheckBox
							label='Valid DEA Certificate'
							checked={newUserData.validDEACert}
							value={newUserData.validDEACert}
							onChange={() => {
								setNewUserData({ ...newUserData, validDEACert: !newUserData.validDEACert })
							}}
						/>
						{/* <div style={{}}>
							<ModalTextBox
								type="text"
								icon="/sprite.svg#icon-provider"
								label="Shown As"
								id="providerName"
								placeholder="Doe"
								value={newUserData.displayAsClinic ? newUserData.clinicName : newUserData.shownAs}
								onChange={(event) => {
									setNewUserData({ ...newUserData, shownAs: event.target.value })
								}}
							/>
							<p className='error_message__deleteClient'>{addDataError.providername}</p>
						</div> */}
					</div>

					{/* 	<div style={{ width: '100%', display: 'flex', justifyContent: 'space-evenly' }}>
					
					<ModalCheckBox
							label='Display name as clinic name'
							checked={newUserData.displayAsClinic}
							value={newUserData.displayAsClinic}
							onChange={() => {
								setNewUserData({ ...newUserData, displayAsClinic: !newUserData.displayAsClinic })
							}
							}
						/> 
					</div>*/}
				</InputsSection>

				<ModalButtonPrimary title="Submit" onClick={handleAddInvite} />
			</ModalForm>
			{/* } */}

		</Modal>
	);
};

export const AddNewModal = ({ data }) => {
	const {
		onCloseAddModal,
		handleAddInvite,
		newUserData,
		setNewUserData,
		newUserDataError,
		setNewUserDataError
	} = data;

	return (
		<Modal
		//show={modalAddState}
		>
			<ModalHeading heading="Add Provider"
				onClose={onCloseAddModal}
			/>
			<ModalForm onSubmit={handleAddInvite} style={{ marginBottom: '2.5rem' }}>
				<InputsSection>
					<ModalTextBox
						type="text"
						icon="/sprite.svg#icon-email"
						label="Email Address"
						id="Email"
						placeholder="email"
						value={newUserData.email}
						onChange={(e) => {
							setNewUserData({ ...newUserData, email: e.target.value })
							setNewUserDataError({ ...newUserDataError, email: '' })
						}}
						error={newUserDataError.email}
					/>

					<ModalTextBox
						type="password" autoComplete="new-password"
						icon="/sprite.svg#icon-phone"
						label="Password"
						id="Email"
						placeholder="****"
						value={newUserData.password}
						onChange={(e) => {
							setNewUserData({ ...newUserData, password: e.target.value })
							setNewUserDataError({ ...newUserDataError, password: '' })
						}}
					//error={newUserDataError.password}
					/>
					{/* <p className='error_message__deleteClient' dangerouslySetInnerHTML={{__html: newUserDataError.password.replace(/\n/g,'<br/>')}} /> */}
				</InputsSection>

				<InputsSection
					style={{
						display: 'flex',
						flexDirection: 'column'
					}}
				>
					<div style={{ width: '100%', display: 'flex', justifyContent: 'space-evenly' }}>
						<ModalCheckBox
							label='Admin'
							value={newUserData.isAdmin}
							onChange={() => {
								setNewUserData({ ...newUserData, isAdmin: !newUserData.isAdmin })
								setNewUserDataError({ ...newUserDataError, isAdmin: '' })
							}}
						/>
						<ModalCheckBox
							label='Provider'
							value={newUserData.isProvider}

							onChange={() => {
								setNewUserData({ ...newUserData, isProvider: !newUserData.isProvider })
								setNewUserDataError({ ...newUserDataError, isAdmin: '' })
							}}
						/>
					</div>
					<p className='error_message__deleteClient'>{newUserDataError.isAdmin}</p>
					<div style={{
						display: 'flex',
						justifyContent: 'flex-start',
						gap: '2rem'
					}}>
						<div style={{}}>
							<ModalTextBox
								type="text"
								icon="/sprite.svg#icon-name"
								label="First Name"
								id="clientName"
								placeholder="Jon"
								value={newUserData.firstName}
								onChange={(e) => {
									setNewUserData({ ...newUserData, firstName: e.target.value })
									setNewUserDataError({ ...newUserDataError, firstName: '' })
								}}
							/>

							<p className='error_message__deleteClient'>{newUserDataError.firstName}</p>
						</div>

						<div style={{}}>
							<ModalTextBox
								type="text"
								icon="/sprite.svg#icon-provider"
								label="Last Name"
								id="providerName"
								placeholder="Doe"
								value={newUserData.lastName}
								onChange={(e) => {
									setNewUserData({ ...newUserData, lastName: e.target.value })
									setNewUserDataError({ ...newUserDataError, lastName: '' })
								}}
							/>
							<p className='error_message__deleteClient'>{newUserDataError.lastName}</p>
						</div>
					</div>
					<div style={{
						display: 'flex',
						justifyContent: 'flex-start',
						gap: '2rem'
					}}>
						<div style={{}}>
							<ModalTextBox
								type="text"
								icon="/sprite.svg#icon-name"
								label="NPI Number"
								id="clientName"
								placeholder="Jon"
								value={newUserData.npi}
								onChange={(e) => setNewUserData({ ...newUserData, npi: e.target.value })}
							/>

							{/* <p className='error_message__deleteClient'>{addDataError.clientname}</p> */}
						</div>

						<div style={{}}>
							<ModalTextBox
								type="text"
								icon="/sprite.svg#icon-provider"
								label="Credential"
								id="providerName"
								placeholder="Doe"
								value={newUserData.credentials}
								onChange={(e) => setNewUserData({ ...newUserData, credentials: e.target.value })}
							/>
							{/* <p className='error_message__deleteClient'>{addDataError.providername}</p> */}
						</div>
					</div>
					<div style={{
						display: 'flex',
						justifyContent: 'flex-start',
						gap: '2rem',
						width: '90%'
					}}>
						<div className='modal_input'>
							<label htmlFor="email" className="modal_input--label">
								Gender
							</label>
							<div className='modal_input--box' style={{ width: '22rem' }}>
								<label htmlFor="email" className="modal_input--iconBox">
									<svg className="modal_input--icon">
										<use xlinkHref={`/sprite.svg#icon-user`} />
									</svg>
								</label>
								<select
									className='filter__input--select'
									value={newUserData.gender}
									onChange={(e) => setNewUserData({ ...newUserData, gender: e.target.value })}
									name='providers'
									id='providers'>
									<option value={null}></option>
									<option value='Male'>Male</option>
									<option value='Female'>Female</option>
									<option value='Other'>Other</option>
								</select>
							</div>
						</div>
						<ModalCheckBox
							label='Valid DEA Certificate'
							value={newUserData.validDEACert}

							onChange={() => {
								setNewUserData({ ...newUserData, validDEACert: !newUserData.validDEACert })
							}
							}
						/>

						{/* <div style={{}}>
							<ModalTextBox
								type="text"
								icon="/sprite.svg#icon-provider"
								label="Shown As"
								id="providerName"
								placeholder="Doe"
								value={newUserData.displayAsClinic ? newUserData.clinicName : newUserData.shownAs}
								onChange={(e) => setNewUserData({ ...newUserData, shownAs: e.target.value })}
							/>
							<p className='error_message__deleteClient'>{addDataError.providername}</p>
						</div> */}
					</div>

					{/* 	<div style={{ width: '100%', display: 'flex', justifyContent: 'space-evenly' }}>
					
						<ModalCheckBox
							label='Display name as clinic name'
							value={newUserData.displayAsClinic}

							onChange={() => {
								setNewUserData({ ...newUserData, displayAsClinic: !newUserData.displayAsClinic })
							}
							}
						/> 
					</div>*/}
				</InputsSection>
				<ModalButtonPrimary title="Submit" onClick={handleAddInvite} />
			</ModalForm>
		</Modal>
	);
};

export const DeleteModal = ({ data }) => {
	const {
		deleteModalState,
		onCloseDeletetModal,
		handleAddInvite,
		handleDelete,
	} = data;

	return (
		<Modal
			show={deleteModalState}
		>
			<ModalHeading heading="Delete Provider"
				onClose={onCloseDeletetModal}
			/>
			{/* {
				addClient && addClient.loading?
				<AddClientSkeleton/>
				: */}
			<ModalForm onSubmit={handleAddInvite} style={{ marginBottom: '2.5rem' }}>

				<h3 style={{ paddingBottom: '2rem', paddingTop: '2rem' }}>Are you sure want to permanently delete this user ?</h3>

				<ModalButtonPrimary title="OK" onClick={handleDelete} style={{ marginRight: '1rem' }} />

			</ModalForm>
			{/* } */}

		</Modal>
	);
};

const ActiveInactiveModal = ({ data }) => {
	const { modalActiveState, onActiveClose, handleActiveInactive, providerClinicsToggle } = data;

	return (

		<Modal show={modalActiveState}>
			<ModalHeading
				heading='Clinic Provider' onClose={onActiveClose}
			/>
			{
				providerClinicsToggle && providerClinicsToggle.loading ?
					(<ToggleUserSkeleton />)
					:
					(
						<Fragment>
							<div className='modalContainer__form'>
								<p>Are you sure you want to change the status of this account?</p>
							</div>
							<div className='modal__actions'>

								<button className='modal__button modal__button--update' onClick={handleActiveInactive}>
									Confirm
								</button>
							</div>
						</Fragment>
					)
			}


		</Modal>
	);
};

export default ActiveInactiveModal;
