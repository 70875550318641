import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Modal from '../../components/Modal';
import ModalHeading from '../../components/Modal/ModalHeading';
import ModalForm from '../../components/Modal/ModalForm';
import { passwordResetPatientAction } from '../../redux/actions/userActions';
import { validatePassword } from '../../utils/validators';
import { UserLoginSkeleton } from '../../components/Skeletons/userLogin';
import { RESETPASSWORDS_RESET } from '../../redux/constants/userConstants';

const ChangePassword = ({ data }) => {
	const { userData, handleEditModalClose, modalChangePasswordState, setModalChangePasswordState } = data;

	const dispatch = useDispatch();
	const passwordData = useSelector((state) => state.passwordData);
	const [passwordError, setpasswordError] = useState('');
	const [ConfirmPasswordError, setConfirmPasswordError] = useState(''); 
	const [currentNewEye, setCurrentNewEye] = useState(false);
	const [currentConfirmEye, setCurrentConfirmEye] = useState(false);
	const [resetOldPassword, setResetOldPassword] = useState({ curPassword: '', newPassword: '', confirmPassword: '' });
 
	const showNewPassword = (e) => {
		var x = document.getElementById('newPwd');
		setCurrentNewEye(!currentNewEye);
		if (x.type === 'password') {
			x.type = 'text';
		} else {
			x.type = 'password';
		}
	};
	const showNewConfirmPassword = (e) => {
		var x = document.getElementById('confirmPwd');
		setCurrentConfirmEye(!currentConfirmEye);
		if (x.type === 'password') {
			x.type = 'text';
		} else {
			x.type = 'password';
		}
	};

	console.log(passwordData, 'passwordData');

	//Reset Password
	React.useEffect(
		() => {
			if (passwordData && passwordData.userInfo && passwordData.userInfo.status) {
				dispatch({ type: RESETPASSWORDS_RESET });
				clearHandler();
				toast.success('Password changed Successfully');
			} else if (passwordData && passwordData.error) {
				toast.error(passwordData.error)
			}
		},
		[passwordData, dispatch]
	);


	const handlePasswordSubmit = (e) => {
		//e.preventDefault();
		if (resetOldPassword.newPassword !== resetOldPassword.confirmPassword) {
			setConfirmPasswordError('Password Does not match')
		} else if (!resetOldPassword.newPassword.trim() || resetOldPassword.newPassword.trim() === '') {
			setpasswordError('Please enter a password');
		} else if (!validatePassword(resetOldPassword.newPassword)) {
			setpasswordError("Password must have at least 8 characters, 1 uppercase, 1 lowercase, and 1 special character.");
		} else if (!resetOldPassword.confirmPassword.trim() || resetOldPassword.confirmPassword.trim() === '') {
			setpasswordError('Please confirm your password');
		} else {
			setConfirmPasswordError('');
			setpasswordError('')
			const formData = {
				password: resetOldPassword.newPassword,
				userId: userData?.data.id
			}
			dispatch(passwordResetPatientAction(formData));
		}
	};

	const clearHandler = () => {
		setModalChangePasswordState(false);
		setConfirmPasswordError('');
		setpasswordError('');
		setResetOldPassword({ curPassword: '', newPassword: '', confirmPassword: '' });
	}

	return (
		<Modal show={modalChangePasswordState}>
			<ModalHeading heading='Change Password' onClose={clearHandler} />
			<ModalForm onSubmit={handlePasswordSubmit} style={{ marginBottom: '2.5rem' }}>
				<div className="profile__password">
					<div className="profile__content patient_profile__content">
						{passwordData && passwordData.loading ? (
							<UserLoginSkeleton />
						) : (
							<div className="filter__input--labels">
								<div className="filter__input--labels">
									<label htmlFor="newPwd" className="filter__input--label">
										New Password
									</label>
									<div className="profile__input">
										<label htmlFor="newPwd" className="filter__input--iconBox">
											<svg className="filter__input--icon">
												<use xlinkHref={`/sprite.svg#icon-key`} />
											</svg>
										</label>
										<input
											type="password" autoComplete="new-password"
											id="newPwd"
											value={resetOldPassword.newPassword}
											onChange={(e) => {
												setResetOldPassword({ ...resetOldPassword, newPassword: e.target.value });
												setpasswordError('');
											}}
											placeholder="••••••••••••••"
											className="modal_input--text2"
										/>

										<svg className="filter__input--icon" onClick={showNewPassword}>
											{currentNewEye ? (
												<use xlinkHref={`/sprite.svg#icon-hidePassword`} />
											) : (
												<use xlinkHref={`/sprite.svg#icon-showPassword`} />
											)}
										</svg>
									</div>
									<p className="error_message">{passwordError}</p>
								</div>
								<div className="filter__input--labels">
									<label htmlFor="confirmPwd" className="filter__input--label">
										Confirm Password
									</label>
									<div className="profile__input">
										<label htmlFor="confirmPwd" className="filter__input--iconBox">
											<svg className="filter__input--icon">
												<use xlinkHref={`/sprite.svg#icon-key`} />
											</svg>
										</label>
										<input
											type="password" autoComplete="new-password"
											id="confirmPwd"
											value={resetOldPassword.confirmPassword}
											onChange={(e) => {
												setResetOldPassword({ ...resetOldPassword, confirmPassword: e.target.value });
												setConfirmPasswordError('');
											}}
											placeholder="••••••••••••••"
											className="modal_input--text2"
										/>
										<svg className="filter__input--icon" onClick={showNewConfirmPassword}>
											{currentConfirmEye ? (
												<use xlinkHref={`/sprite.svg#icon-hidePassword`} />
											) : (
												<use xlinkHref={`/sprite.svg#icon-showPassword`} />
											)}
										</svg>
									</div>
									<p className="error_message">{ConfirmPasswordError}</p>
								</div>
							</div>
						)}
					</div>
				</div>

				<button className="profile__button--password" onClick={handlePasswordSubmit}>Change Password</button>
			</ModalForm>
		</Modal>
	);
};

export default ChangePassword;
