import React, { useState } from 'react';
import classes from './SelectBox.module.css';
import Select, { components } from 'react-select';

const MultiSelectBox = ({ children, icon, name, label, value, onChange, errorMessage, multiple, inputValue, onInputChange }) => {
  const colourStyles = {
    menuList: styles => ({
      ...styles,
      backgroundColor: '#FFF',
      zIndex: 999,
      width: '100%',
    }),
    option: (styles, { isFocused, isSelected }) => ({
      ...styles,
      color: isSelected ? '#000' : '#000',
      backgroundColor: isSelected ? '#FFF' : '#FFF',
      zIndex: 1,
    }),
    menu: base => ({
      ...base,
      zIndex: 999,
    }),
  };

  const NoOptionsMessage = props => {
    return (
      <components.NoOptionsMessage {...props}>
        <span>No users found with name</span>
      </components.NoOptionsMessage>
    );
  };
  return (
    <div className={classes.form_input}>
      {label && (
        <label htmlFor={label} className={classes.form_input__label}>
          {label}
        </label>
      )}
      <div className={classes.textBox}>
        {icon && (
          <label className={classes.textBox__label} style={{ top: '0rem' }}>
            <svg className={classes.textBox__label_icon}>
              <use xlinkHref={`/assets/sprite.svg#icon-${icon}`} />
            </svg>
          </label>
        )}
        <div className={classes.textBox__input}>
          <Select
            value={value}
            isMulti={multiple}
            options={children}
            inputValue={inputValue}
            onChange={onChange}
            styles={colourStyles}
            placeholder={label + ' - [Patient Name (DOB)]'}
            className={classes.textBox__input_box}
            onInputChange={onInputChange}
          />
        </div>
      </div>

      {errorMessage && <p className={classes.form_input__error}>{errorMessage}</p>}
    </div>
  );
};


export const MultiSelectBoxNew = ({
  children,
  icon,
  dontshowLabel = false,
  label,
  value,
  onChange,
  containerStyle,
  errorMessage,
  multiple,
  inputValue,
  onInputChange,
  customStyles
}) => {
  const colourStyles = {
    menuList: styles => ({
      ...styles,
      backgroundColor: '#FFF',
      zIndex: 999,
      width: '100%',
    }),
    option: (styles, { isFocused, isSelected }) => ({
      ...styles,
      color: isSelected ? '#000' : '#000',
      backgroundColor: isSelected ? '#DDD' : '#FFF',
      zIndex: 1,
    }),
    menu: base => ({
      ...base,
      zIndex: 999,
    }),
    ...customStyles, // Apply custom styles if provided
  };

  return (
    <div className={classes.form_input} style={containerStyle}>
      {label && (
        <label htmlFor={label} className={classes.form_input__label}>
          {dontshowLabel ? null : label}
        </label>
      )}
      <div className={classes.textBox}>
        {icon && (
          <label className={classes.textBox__label} style={{ top: '0rem' }}>
            <svg className={classes.textBox__label_icon}>
              <use xlinkHref={`/assets/sprite.svg#icon-${icon}`} />
            </svg>
          </label>
        )}
        <div className={classes.textBox__input}>
          <Select
            isMulti={multiple}
            options={children}
            inputValue={inputValue}
            onChange={onChange}
            styles={colourStyles}
            placeholder={label}
            className={classes.textBox__input_box}
            onInputChange={onInputChange}
            value={value}
          />
        </div>
      </div>
      {errorMessage && <p className={classes.form_input__error}>{errorMessage}</p>}
    </div>
  );
};


export default MultiSelectBox;

