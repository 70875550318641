import React, { Fragment } from 'react';
import Modal from '../../../components/Modal';
import ModalHeading from '../../../components/Modal/ModalHeading';
import { DeleteUserSkeleton } from '../../../components/Skeletons/manageUsers'
import { ModalButtonPrimary } from '../../../components/Modal/ModalButton';
import ModalForm from '../../../components/Modal/ModalForm';
import { useDispatch } from 'react-redux';
import { deleteQuiz } from '../../../redux/actions/quizActions';
import { InputsSection } from '../../../components/Modal/InputsSection';

const DeleteQuiz = ({ data, onRefresh }) => {
	const { setDeleteModalState, deleteModalState, selectedQuizId } = data;
	const dispatch = useDispatch()

	const onDeleteHandler = async (d) => {
		await dispatch(deleteQuiz(selectedQuizId));
		if (onRefresh) onRefresh();
		setDeleteModalState(false)
	};

	return (
		<Modal
			show={deleteModalState}
		>
			<ModalHeading heading="Delete Quiz"
				onClose={() => setDeleteModalState(false)}
			/>
			<Fragment>
				<ModalForm>
					<InputsSection style={{ justifyContent: 'inherit' }}>
						<span className="modal__text">Are you sure want to permanently delete this quiz ?</span>
					</InputsSection>

						<ModalButtonPrimary className='modal__actions' title='Yes' 	onClick={onDeleteHandler} />
				</ModalForm>
			</Fragment>
		</Modal>
	);
};

export default DeleteQuiz;
