import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Styles from './CreateQuiz.module.css';
import Content from '../../components/Content';
import CreateNewQuiz from './CreateQuiz';
import QuizDetails from './QuizDetail';
import { createQuiz, getProductCategory, getQuestionList,getQuestionCriteriaByType, getCriteriaOptions, getCriteriaType } from '../../redux/actions/quizActions';
import { useHistory } from 'react-router-dom';

const CreateQuiz = () => {
  const dispatch = useDispatch();
  const userLogin = useSelector(state => state.userLogin);

  const [totalPageSize, setTotalPageSize] = useState(1);
  const [criteriaTypeList, setCriteriaTypeList] = useState([{ type: [], criteria: [] }]);
  const [selectedCriteriaType, setSelectedCriteriaType] = useState(null);
  const [quizData, setQuizData] = useState(null);
  const [selectedFrequency, setSelectedFrequency] = useState({ value: '', error: '' });
  const [selectedProduct, setSelectedProduct] = useState({ title: null, value: null, error: '' });
  const [currentStep, setCurrentStep] = useState(1);
  const [productsCategory, setProductsCategory] = useState([]); 
  const quizQuestionList = useSelector((state) => state.quizQuestionList);
  const [criteriaTypes, setCriteriaTypes] = useState();
  const [criteriaSelectType, setCriteriaSelectType] = useState([]); 
  const [criteria, setCriteria] = useState([
    { label: 'Lower Back', value: 'Lower Back' },
    { label: 'Upper Back', value: 'Upper Back' },
    { label: 'Lt Buttocks', value: 'Lt Buttocks' },
    { label: 'Lt Elbow', value: 'Lt Elbow' }, 
    { label: 'Rt Shoulder', value: 'Rt Shoulder' },
    { label: 'Lt Groin', value: 'Lt Groin' },
    { label: 'Lt Knee', value: 'Lt Knee' },
    { label: 'Rt Chest/Breast', value: 'Rt Chest/Breast' },
    { label: 'Rt Ankle/Foot', value: 'Rt Ankle/Foot' },
    { label: 'Pelvis', value: 'Pelvis' },
    { label: 'Lt Ankle/Foot', value: 'Lt Ankle/Foot' },
    { label: 'Rt Hip', value: 'Rt Hip' },
    { label: 'Rt Jaw', value: 'Rt Jaw' },
    { label: 'Rt Groin', value: 'Rt Groin' },
    { label: 'Lt Shoulder', value: 'Lt Shoulder' },
    { label: 'Rt Wrist/Hand', value: 'Rt Wrist/Hand' },
    { label: 'Lt Upper Arm', value: 'Lt Upper Arm' },
    { label: 'Lt Upper Leg', value: 'Lt Upper Leg' },
    { label: 'Rt Upper Leg', value: 'Rt Upper Leg' },
]);
  const history = useHistory();
  const [criteriaArray, setCriteriaArray] = useState([]);
  const getProductHandler = React.useCallback(async () => {
    if (userLogin && userLogin.userInfo && userLogin.userInfo.token) {
      const data = await dispatch(getProductCategory(userLogin));
      if (data?.data) {
        setProductsCategory(data.data);
      }
    }
  }, [userLogin]);

  React.useEffect(() => {
    getProductHandler();
  }, [getProductHandler]);

  useEffect(() => {
    if (selectedProduct && selectedProduct.value) {
      dispatch(getQuestionList(selectedProduct.value));
    }
  }, [selectedProduct]);

  const handleNextStep = () => {
    setCurrentStep(2);
  };

  const handlePreviousStep = () => {
    setCurrentStep(1);
  };

  const handleSubmitQuiz = () => {
    let hasError = false;

    setSelectedFrequency((prev) => ({ ...prev, error: '' }));
    setSelectedProduct((prev) => ({ ...prev, error: '' }));

    if (!selectedFrequency.value) {
      setSelectedFrequency((prev) => ({ ...prev, error: 'Frequency is required.' }));
      hasError = true;
    }
    if (!selectedProduct.value) {
      setSelectedProduct((prev) => ({ ...prev, error: 'Product Category is required.' }));
      hasError = true;
    }

    if (hasError) return;

    const formattedCriteriaList = criteriaTypeList.map(item => {
      return {
          type: item.type,
          criteria: Array.isArray(item.criteria)
              ? item.criteria.map(c => c.label).join(', ')
              : item.criteria.label
      };
  });


    const data = {
      frequency: selectedFrequency.value,
      productId: selectedProduct.value,
      criteriaList: formattedCriteriaList
    };
    setQuizData(data);
    handleNextStep();
  };

  const onDataSubmit = () => {
  dispatch(createQuiz(quizData, () => history.push('/clinic/quiz-settings')));
  }

  const handleCancelClick = () => {
    history.push('/clinic/quiz-settings');
  };
  
  return (
    <Content
      currentMenu="Create New Quiz"
      headerTitle="Create New Quiz"
      addBtnText="Create Quiz"
      headerImage={true}
      addBtnIcon="/sprite.svg#icon-plus"
    >
      {currentStep === 1 && (
        <CreateNewQuiz
          productsCategory={productsCategory}
          criteriaTypeList={criteriaTypeList}
          setCriteriaTypeList={setCriteriaTypeList}
          setCriteriaTypes={setCriteriaTypes}
          setCriteria={setCriteria}
          criteria={criteria}
          criteriaTypes={criteriaTypes}
          setCriteriaSelectType={setCriteriaSelectType}
          criteriaSelectType={criteriaSelectType}
          selectedProduct={selectedProduct}
          setSelectedProduct={setSelectedProduct}
          selectedFrequency={selectedFrequency}
          setSelectedFrequency={setSelectedFrequency}
          handleSubmitQuiz={handleSubmitQuiz}
          selectedCriteriaType={selectedCriteriaType}
          setSelectedCriteriaType={setSelectedCriteriaType}
          handleCancelClick={handleCancelClick}
        />
      )}
      {currentStep === 2 && (
        <QuizDetails
          quizData={quizData}
          onBack={handlePreviousStep}
          onDataSubmit={onDataSubmit}
          selectedProduct={selectedProduct}
          quizQuestionList={quizQuestionList}
        />
      )}
    </Content>
  );
};

export default CreateQuiz;
