import React, { Fragment, useEffect } from 'react';
import Modal from '../../../components/Modal';
import ModalHeading from '../../../components/Modal/ModalHeading';
import { DeleteUserSkeleton } from '../../../components/Skeletons/manageUsers'
import { ModalButtonPrimary } from '../../../components/Modal/ModalButton';
import ModalForm from '../../../components/Modal/ModalForm';
import styles from './QuizModal.module.css'
import { useDispatch, useSelector } from 'react-redux';
import { getQuestionList } from '../../../redux/actions/quizActions';

const QuestionModal = ({ data, onRefresh }) => {
	const dispatch = useDispatch()
	const quizQuestionList = useSelector((state) => state.quizQuestionList);
	const { questionModalState, setQuestionModalState, deleteManageUser, selectedProductId } = data;

	useEffect(() => {
		if (selectedProductId) {
			dispatch(getQuestionList(selectedProductId));
		}
	}, [selectedProductId]);

	const onCloseDeletetModal = () => {
		setQuestionModalState(false)
	}

	return (
		<Modal
			show={questionModalState}
		>
			<ModalHeading heading="Quiz Questions"
				onClose={onCloseDeletetModal}
			/>
			{/* {
				addClient && addClient.loading?
				<AddClientSkeleton/>
				: */}
			<ModalForm
				// onSubmit={handleAddInvite} 
				style={{ marginBottom: '2.5rem', maxHeight:'500px',  overflow: 'auto'}}>

				<div className={styles.mainDropdownSection1}>
					<div className={styles.criteriaDropdownSection1}>
						{quizQuestionList &&
							quizQuestionList.data &&
							quizQuestionList.data.map((item, index) =>
								<React.Fragment>
									<span className={styles.question1}>{index + 1}. {item.questionText}</span>
									<div className={styles.questionSection}>
										<span className={styles.question}>Options :</span>
										<div>
											{
												item.answerType &&
												item.answerType == "SingleSelect" || item.answerType == "MultiSelect"  ?
												item &&
												item.answers &&
												item.answers.map((ans, ind) => 
													<div className={styles.answer}>{ind + 1}. {ans.displayText}</div> 
												)
												:
												<span className={styles.answer}>Yes/No</span>
											}
										</div>
									</div>
								</React.Fragment>
							)}

					</div>
				</div>

			</ModalForm>
			{/* } */}

		</Modal>
	);
};

export default QuestionModal;
