import React from 'react';
import Styles from './CreateQuiz.module.css'

const QuizDetails = ({ onBack, quizData, onDataSubmit, selectedProduct, quizQuestionList }) => {
	return (
		<>
			<div className={Styles.mainContent}>
				<span className={Styles.tabTitle}>Product Category</span>
				<div className={Styles.mainDropdownSection1}>
					<div className={Styles.criteriaDropdownSection1}>
						<div className={Styles.questionSection}>
							<span className={Styles.question}>Product Name :</span>
							<span className={Styles.answer}>{selectedProduct.title}</span>
						</div>
					</div>
				</div>
				<span className={Styles.tabTitle}>Frequency</span>
				<div className={Styles.mainDropdownSection1}>
					<div className={Styles.criteriaDropdownSection1}>
						<div className={Styles.questionSection}>
							<span className={Styles.question}>Frequency :</span>
							<span className={Styles.answer}>{quizData.frequency}</span>
						</div>
					</div>
					<div>
					</div>
				</div>
				<span className={Styles.tabTitle}>Criteria</span>
				<div className={Styles.mainDropdownSection1}>
					<div className={Styles.criteriaDropdownSection1}>
						{quizData &&
							quizData.criteriaList &&
							quizData.criteriaList.map((item) =>
								<div className={Styles.questionSection}>
									<span className={Styles.question}>{item.type} :</span>
									<span className={Styles.answer}>{item.criteria}</span>
								</div>)
						}
					</div>

				</div>
				<span className={Styles.tabTitle}>Quiz Questions</span>
				<div className={Styles.mainDropdownSection1}>
					<div className={Styles.criteriaDropdownSection1}>

						{quizQuestionList &&
							quizQuestionList.data &&
							quizQuestionList.data.map((item, index) =>
								<React.Fragment>
									<span className={Styles.question1}>{index + 1}. {item.questionText}</span>
									<div className={Styles.questionSection}>
										<span className={Styles.question}>Options :</span>
										<div>
												{
													item.answerType&&
													item.answerType == "SingleSelect" || item.answerType == "MultiSelect"  ?
														item &&
														item.answers &&
														item.answers.map((ans, ind) =>
															<div className={Styles.answer}>{ind+1}. {ans.displayText}</div>
														)
														:
														<span className={Styles.answer}>Yes/No</span>
												}
										</div>
									</div>
								</React.Fragment>
							)}
					</div>
				</div>
			</div>
			<div className={Styles.bottomButtonsSection}>
				<button className={Styles.loginContainer__button1} onClick={onBack}>
					<span className={Styles.loginContainer__button_text}>PREVIOUS</span>
					<div className={Styles.loginContainer__button__iconBox}>
						<svg className={Styles.loginContainer__button__icon1}>
							<use xlinkHref="/sprite.svg#icon-login" />
						</svg>
					</div>
				</button>
				<button className={Styles.loginContainer__button} onClick={onDataSubmit}>
					<span className={Styles.loginContainer__button_text}>SUBMIT</span>
					<div className={Styles.loginContainer__button__iconBox}>
						<svg className={Styles.loginContainer__button__icon}>
							<use xlinkHref="/sprite.svg#icon-login" />
						</svg>
					</div>
				</button>
			</div>
		</>
	);
};

export default QuizDetails;
