import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Content from '../../components/Content';
import ResultsData from './ResultsData';
import ReactPaginate from 'react-paginate';
import { getQuizResults } from '../../redux/actions/quizActions';
import DeleteQuiz from './Modals/DeleteQuiz';
import QuestionModal from './Modals/QuestionModal';

const PatientResults = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    const [totalPageSize, setTotalPageSize] = useState(1);
    const [pageNumber, setPageNumber] = useState(1);
    const [questionModalState, setQuestionModalState] = useState(false);
    const [deleteModalState, setDeleteModalState] = useState(false);
    const [selectedProductId, setSelectedProductId] = useState('');
    const [selectedQuizId, setSelectedQuizId] = useState('');
    const pageLimit = 10;

    const quizResults = useSelector((state) => state.quizResults);

    const getQuizResultsList = async (selectedPage = 1) => {
        const data = {
            pageNumber: selectedPage,
            rowsOfPage: pageLimit,
            totalCount: 0,
        };
        await dispatch(getQuizResults(data));
    };

    useEffect(() => {
        getQuizResultsList(pageNumber);
    }, [dispatch, pageNumber]);

    useEffect(() => {
        if (quizResults?.data?.totalCount !== undefined) {
            setTotalPageSize(Math.ceil(quizResults.data.totalCount / pageLimit));
        }
    }, [quizResults]);

    const handlePageChange = (currentPage) => {
        const selectedPage = currentPage.selected + 1;
        setPageNumber(selectedPage);
    };

    const refreshQuizResults = () => {
        getQuizResultsList(pageNumber);
    };

    return (
        <Content
            currentMenu="Quiz Settings"
            headerTitle="Quiz Settings"
            addBtn={true}
            headerImage={true}
            addBtnClick={() => history.push('/clinic/create-quiz')}
            addBtnText="Create Quiz"
            addBtnIcon="/sprite.svg#icon-plus"
        >
            <ResultsData
                data={{
                    pageNumber,
                    setSelectedProductId,
                    selectedProductId,
                    selectedQuizId,
                    setSelectedQuizId,
                    setDeleteModalState,
                    setQuestionModalState,
                }}
                onRefresh={refreshQuizResults}
                usersList={quizResults}
            />
            <div className="tableContainer--paginater">
                {
                    quizResults &&
                    quizResults?.data &&
                    quizResults?.data?.data &&
                    <ReactPaginate
                        previousLabel={'<'}
                        nextLabel={'>'}
                        pageCount={totalPageSize}
                        onPageChange={handlePageChange}
                        marginPagesDisplayed={1}
						pageRangeDisplayed={5}
                        containerClassName={'paginationBttns'}
                        previousLinkClassName={'previousBttn'}
                        nextLinkClassName={'nextBttn'}
                        disabledClassName={'paginationDisabled'}
                        activeClassName={'paginationActive'}
                        forcePage={pageNumber - 1}
                    />
					
                }
            </div>
            <DeleteQuiz onRefresh={refreshQuizResults} data={{
                setDeleteModalState,
                selectedQuizId,
                deleteModalState
            }} />
            <QuestionModal data={{
                questionModalState,
                setSelectedProductId,
                selectedProductId,
                setQuestionModalState
            }} />
        </Content>
    );
};

export default PatientResults;
