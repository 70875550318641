import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import ReactPaginate from 'react-paginate';
import { CLINIC_FILTER_RESET, CLINIC_PROVIDER_RESET } from '../../redux/constants/providerConstants'

import './ClinicScreen.css'
import FilterUsers from './FilterUsers'
import ShowData from './ShowData'
import { clinicFilterAction, clinicProvidersAction, providerAssessmentAction } from '../../redux/actions/providerActions'
import Content from '../../components/Content';
import { logout } from '../../redux/actions/userActions';
import { getProductCategory, getQuizResultsList } from '../../redux/actions/quizActions';
import { GET_QUIZ_FILTER_RESULTS_RESET } from '../../redux/constants/quizResults';

const QuizResults = ({ history }) => {
    const dispatch = useDispatch();

    const clinicProvider = useSelector((state) => state.clinicProvider);
    const clinicFilter = useSelector((state) => state.clinicFilter);
    const quizResultsFilterList = useSelector((state) => state.quizResultsFilterList);
    const userLogin = useSelector((state) => state.userLogin);
    const [productsCategory, setProductsCategory] = useState([]); 

    const [userIndex, setUserIndex] = useState(1);
    const [usersList, setUsersList] = useState([]);
    const [totalusersList, setTotalUsersList] = useState([]);
    const perPageUser = 6;
    const totalPages = totalusersList / perPageUser;
    const [showFilter, setShowFilter] = useState(true);
    const [productId, setProductId] = useState(null);
    const [totalPageSize, setTotalPageSize] = useState(1);
    const [pageNumber, setPageNumber] = useState(1);
    const pageLimit = 10;

    const [filterData, setFilterData] = useState({
        email: "",
        productId: productId,
        firstName: "",
        lastName: "",
        birthDate: "",     
        pageNumber: pageNumber,
        rowsOfPage: 10,
    })

    const getProductHandler = React.useCallback(async () => {
        if (userLogin && userLogin.userInfo && userLogin.userInfo.token) {
          const data = await dispatch(getProductCategory(userLogin));
          if (data?.data) {
            setProductsCategory(data.data);
          }
        }
      }, [userLogin]);

      React.useEffect(() => {
        getProductHandler();
      }, [getProductHandler]);


    useEffect(() => {
        if (quizResultsFilterList?.data?.totalCount !== undefined) {
            setTotalPageSize(Math.ceil(quizResultsFilterList?.data?.totalCount / pageLimit));
        }
    }, [quizResultsFilterList]);

    useEffect(() => {
        return () => {
            dispatch({ type: GET_QUIZ_FILTER_RESULTS_RESET })
        }
    }, [dispatch])

    const handleAssessment = (id) => {
        dispatch(providerAssessmentAction(id))
    }

    useEffect(() => {
        dispatch( getQuizResultsList(filterData))
    }, [dispatch, pageNumber]);

    const handleClear = () => {
        setFilterData({
            ...filterData,
            email: "",
            // clinicId: "" ,
            productId: null,
            firstName: "",
            lastName: "",
            birthDate: "" ,
            pageNumber: pageNumber,
            rowsOfPage: 10,
        })
        var formData = {
            email: "",
            // clinicId: "" ,
            productId: null,
            firstName: "",
            lastName: "",
            birthDate: "" ,
            pageNumber: pageNumber,
            rowsOfPage: 10,
        }
        setUserIndex(1);
        dispatch(getQuizResultsList(formData));

    }

    const handleFilter = () => {
        var formData = {
            email: filterData.email,
            clinicId: filterData.clinicId, 
            productId: filterData.productId,
            firstName: filterData.firstName,
            lastName: filterData.lastName,
            birthDate: filterData.birthDate,     
            pageNumber: pageNumber,
            rowsOfPage: 10,
        }
        setUserIndex(1); 
        dispatch(getQuizResultsList(formData)); 
    
    };

    const handlePageChange = (currentPage) => {
        const selectedPage = currentPage.selected + 1;
        setPageNumber(selectedPage);
    };

    return (
        <Content
            current="Patient Quiz Results" headerTitle="Patient Quiz Results" currentMenu="Patient Quiz Results"
        >
            <FilterUsers
                data={{
                    showFilter,
                    setShowFilter,
                    clinicProvider,
                    productsCategory,
                    handleClear,
                    filterData,
                    setFilterData,
                    handleFilter
                }}
            />
            <ShowData
                data={{
                    clinicFilter,
                    showFilter,
                    handleAssessment,
                    // usersList,
                    quizResultsFilterList,
                    userIndex
                }}
                loading={quizResultsFilterList && quizResultsFilterList.loading} />

            {quizResultsFilterList &&
                quizResultsFilterList.data &&
                (
                    <div className="tableContainer--paginater">
                <ReactPaginate
                        previousLabel={'<'}
                        nextLabel={'>'}
                        pageCount={totalPageSize}
                        onPageChange={handlePageChange}
                        marginPagesDisplayed={1}
						pageRangeDisplayed={5}
                        containerClassName={'paginationBttns'}
                        previousLinkClassName={'previousBttn'}
                        nextLinkClassName={'nextBttn'}
                        disabledClassName={'paginationDisabled'}
                        activeClassName={'paginationActive'}
                        forcePage={pageNumber - 1}
                    />
                    </div>
                )}
        </Content>
    )
}

export default QuizResults
