import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Styles from './CreateQuiz.module.css';
import Content from '../../components/Content';
import CreateNewQuiz from './CreateQuiz';
import QuizDetails from './QuizDetail';
import { createQuiz, getProductCategory, getQuestionList, getQuestionCriteriaByType, getCriteriaOptions, getCriteriaType, getQuizById, editQuiz } from '../../redux/actions/quizActions';
import { useHistory, useParams } from 'react-router-dom';

const EditQuiz = () => {
  const dispatch = useDispatch();
  const userLogin = useSelector(state => state.userLogin);
  const { quizId } = useParams();

  const [totalPageSize, setTotalPageSize] = useState(1);
  const [criteriaTypeList, setCriteriaTypeList] = useState([{ type: '', criteria: [] }]);
  const [selectedCriteriaType, setSelectedCriteriaType] = useState(null);
  const [quizData, setQuizData] = useState(null);
  const [selectedFrequency, setSelectedFrequency] = useState({ value: '', error: '' });
  const [selectedProduct, setSelectedProduct] = useState({ value: '', title: '', quizResultId: '', error: '' });
  const [selectedCategory, setSelectedCategory] = useState({ value: '', title: '' , error: '' });
  const [currentStep, setCurrentStep] = useState(1);
  const [criteriaArrays, setCriteriaArrays] = useState([]);
  const [productsCategory, setProductsCategory] = useState([]);
  const quizQuestionList = useSelector((state) => state.quizQuestionList);
  const [criteriaTypes, setCriteriaTypes] = useState();
  const [criteriaSelectType, setCriteriaSelectType] = useState([]);
  const [criteria, setCriteria] = useState([]);
  const history = useHistory();
  const [criteriaArray, setCriteriaArray] = useState([]);
  const getProductHandler = React.useCallback(async () => {
    if (userLogin && userLogin.userInfo && userLogin.userInfo.token) {
      const data = await dispatch(getProductCategory(userLogin));
      if (data?.data) {
        setProductsCategory(data.data);
      }
    }
  }, [userLogin]);

  React.useEffect(() => {
    getProductHandler();
  }, [getProductHandler]);


  const getQuizResultById = useCallback(async (id, index) => {
    if (userLogin && userLogin.userInfo && userLogin.userInfo.token) {
        const data = await dispatch(getQuizById(userLogin, quizId));
        setSelectedFrequency({ value: data.data.quizresult.frequency || '', error: '' });
        setSelectedProduct({ 
          value: data.data.quizresult.productId, 
          quizResultId: data.data.quizresult.quizResultId,
          title: data.data.quizresult.productName, 
          error: '' });
        setSelectedCategory({ value: data.data.quizresult.productId	 || '', title: data.data.quizresult.productName	|| '' , error: '' });
        const criteriaList = data.data.criteria.map((item) => ({
          type: item.title, // Mapping the backend title to type
          multiSelect: item.multiSelect,
          criteria: item.value.map((val) => ({
              value: val,
              label: val,
          })),
      }));

      const allCriteriaOptions = await Promise.all(
        criteriaList.map(async (criteria, index) => {
            const optionsData = await dispatch(getQuestionCriteriaByType(userLogin, criteria.type));
            return optionsData.data.map(option => ({
                value: option.displayText,
                label: option.displayText,
            }));
        })
    );

    // Set the criteriaArrays to contain all options
    setCriteriaArrays(allCriteriaOptions);

      setCriteriaTypeList(criteriaList);
        
     
    }
}, [userLogin, dispatch]);


useEffect(() => {
  getQuizResultById()
}, []);

  useEffect(() => {
    if (selectedProduct && selectedProduct.value) {
      dispatch(getQuestionList(selectedProduct.value));
    }
  }, [selectedProduct]);

  const handleNextStep = () => {
    setCurrentStep(2);
  };

  const handlePreviousStep = () => {
    setCurrentStep(1);
  };


  const handleSubmitQuiz = () => {
    let hasError = false;

    setSelectedFrequency((prev) => ({ ...prev, error: '' }));
    setSelectedProduct((prev) => ({ ...prev, error: '' }));

    if (!selectedFrequency.value) {
      setSelectedFrequency((prev) => ({ ...prev, error: 'Frequency is required.' }));
      hasError = true;
    }
    if (!selectedCategory.value) {
      setSelectedProduct((prev) => ({ ...prev, error: 'Product Category is required.' }));
      hasError = true;
    }

    if (hasError) return;

    const formattedCriteriaList = criteriaTypeList.map(item => {
      return {
        type: item.type,
        criteria: Array.isArray(item.criteria)
          ? item.criteria.map(c => c.label).join(', ')
          : item.criteria.label
      };
    });
    const data = {
      frequency: selectedFrequency.value,
      productId: selectedProduct.value,
      quizResultId: selectedProduct.quizResultId,
      criteriaList: formattedCriteriaList
    };
    setQuizData(data);
    handleNextStep();
  };

  const onDataSubmit = () => {
    dispatch(editQuiz(quizData, () => history.push('/clinic/quiz-settings')));
  }

  return (
    <Content
      currentMenu="Create New Quiz"
      headerTitle="Edit Quiz"
      headerImage={true}
      addBtnIcon="/sprite.svg#icon-plus"
    >
      {currentStep === 1 && (
        <CreateNewQuiz
          productsCategory={productsCategory}
          criteriaTypeList={criteriaTypeList}
          setCriteriaTypeList={setCriteriaTypeList}
          setCriteriaTypes={setCriteriaTypes}
          setCriteriaArrays={setCriteriaArrays}
          criteriaArrays={criteriaArrays}
          setCriteria={setCriteria}
          criteria={criteria}
          criteriaTypes={criteriaTypes}
          setCriteriaSelectType={setCriteriaSelectType}
          criteriaSelectType={criteriaSelectType}
          selectedProduct={selectedProduct}
          selectedCategory={selectedCategory}
          setSelectedProduct={setSelectedProduct}
          setSelectedCategory={setSelectedCategory}
          selectedFrequency={selectedFrequency}
          setSelectedFrequency={setSelectedFrequency}
          handleSubmitQuiz={handleSubmitQuiz}
          selectedCriteriaType={selectedCriteriaType}
          setSelectedCriteriaType={setSelectedCriteriaType}
        />
      )}
      {currentStep === 2 && (
        <QuizDetails
          quizData={quizData}
          onBack={handlePreviousStep}
          onDataSubmit={onDataSubmit}
          selectedProduct={selectedProduct}
          quizQuestionList={quizQuestionList}
        />
      )}
    </Content>
  );
};

export default EditQuiz;
