import React, { Fragment } from 'react';
import Modal from '../../../components/Modal';
import ModalHeading from '../../../components/Modal/ModalHeading';
import {DeleteUserSkeleton} from '../../../components/Skeletons/manageUsers'
import { ModalButtonPrimary } from '../../../components/Modal/ModalButton';
import ModalForm from '../../../components/Modal/ModalForm';

const DeleteCriteria = ({ data }) => {
	const { showModal, onDeleteModalClose, handleDeleteCriteria } = data;
	
	return (
		<Modal
			show={showModal}
		>
			<ModalHeading heading="Delete Criteria"
				onClose={onDeleteModalClose}
			/>
			{/* {
				addClient && addClient.loading?
				<AddClientSkeleton/>
				: */}
			<ModalForm 
			// onSubmit={handleAddInvite} 
			style={{ marginBottom: '2.5rem' }}>

				<h3 style={{ paddingBottom: '2rem', paddingTop: '0.4rem' }}>Are you sure want to delete this criteria ?</h3>

				<ModalButtonPrimary title="OK" 
				
				onClick={handleDeleteCriteria}
				 style={{ marginRight: '1rem' }} />

			</ModalForm>
			{/* } */}

		</Modal>
	);
};

export default DeleteCriteria;
