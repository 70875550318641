export const GET_QUIZ_RESULTS_REQUEST = 'GET_QUIZ_RESULTS_REQUEST';
export const GET_QUIZ_RESULTS_SUCCESS = 'GET_QUIZ_RESULTS_SUCCESS';
export const GET_QUIZ_RESULTS_FAIL = 'GET_QUIZ_RESULTS_FAIL';
export const GET_QUIZ_RESULTS_RESET = 'GET_QUIZ_RESULTS_RESET';

export const GET_QUIZ_FILTER_RESULTS_REQUEST = 'GET_QUIZ_FILTER_RESULTS_REQUEST';
export const GET_QUIZ_FILTER_RESULTS_SUCCESS = 'GET_QUIZ_FILTER_RESULTS_SUCCESS';
export const GET_QUIZ_FILTER_RESULTS_FAIL = 'GET_QUIZ_FILTER_RESULTS_FAIL';
export const GET_QUIZ_FILTER_RESULTS_RESET = 'GET_QUIZ_FILTER_RESULTS_RESET';

export const CREATE_QUIZ_RESULTS_REQUEST = 'CREATE_QUIZ_RESULTS_REQUEST';
export const CREATE_QUIZ_RESULTS_SUCCESS = 'CREATE_QUIZ_RESULTS_SUCCESS';
export const CREATE_QUIZ_RESULTS_FAIL = 'CREATE_QUIZ_RESULTS_FAIL';
export const CREATE_QUIZ_RESULTS_RESET = 'CREATE_QUIZ_RESULTS_RESET'; 

export const GET_QUESTION_LIST_REQUEST = 'GET_PRODUCT_CATEGORY_REQUEST';
export const GET_QUESTION_LIST_SUCCESS = 'GET_QUESTION_LIST_SUCCESS';
export const GET_QUESTION_LIST_FAIL = 'GET_QUESTION_LIST_FAIL';
export const GET_QUESTION_LIST_RESET = 'GET_QUESTION_LIST_RESET';

